import "core-js/modules/es.object.to-string";
// 展会谈
export default [{
  path: '/exhibitionSelect',
  name: 'ExhibitionSelect',
  component: function component() {
    return import('@/views/exhibition/ExhibitionSelect');
  },
  meta: {
    title: 'exhibition_choice',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/exhibitionTalkDetail',
  name: 'ExhibitionTalkDetail',
  component: function component() {
    return import('@/views/exhibition/ExhibitionTalkDetail');
  },
  meta: {
    title: 'exhibition_details',
    keepAlive: false
  }
}, {
  path: '/signupView',
  name: 'SignupView',
  component: function component() {
    return import('@/views/exhibition/SignupView');
  },
  meta: {
    title: 'exhibitor_registration',
    keepAlive: false
  }
}, {
  path: '/visitorSignup',
  name: 'VisitorSignup',
  component: function component() {
    return import('@/views/exhibition/model/VisitorSignup');
  },
  meta: {
    title: 'registration_list',
    keepAlive: false
  }
}, {
  path: '/signUpSuccess',
  name: 'SignUpSuccess',
  component: function component() {
    return import('@/views/exhibition/model/SignUpSuccess');
  },
  meta: {
    title: 'success',
    keepAlive: false
  }
}, {
  path: '/exhibition-area-nav',
  name: 'ExhibitionAreaNav',
  component: function component() {
    return import('@/views/exhibition/ExhibitionAreaNav');
  },
  meta: {
    title: 'exhibition_guide',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.exhibition-area-page'
  }
}, {
  path: '/exhibition-area-detail',
  name: 'ExhibitionAreaDetail',
  component: function component() {
    return import('@/views/exhibition/model/ExhibitionAreaDetail');
  },
  meta: {
    title: 'show_area_navigation_details',
    keepAlive: true
  }
}, {
  path: '/meeting-list',
  name: 'MeetingList',
  component: function component() {
    return import('@/views/exhibition/meeting/MeetingList');
  },
  meta: {
    title: 'mini_html_meeting_list',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/meeting-details',
  name: 'MeetingDetails',
  component: function component() {
    return import('@/views/exhibition/meeting/MeetingDetails');
  },
  meta: {
    title: 'base_meeting_detail',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/yun-meeting',
  name: 'YunMeeting',
  component: function component() {
    return import('@/views/exhibition/meeting/YunMeeting');
  },
  meta: {
    title: 'mini_html_meeting_list',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/meeting-search',
  name: 'MeetingSearch',
  component: function component() {
    return import('@/views/exhibition/meeting/MeetingSearch');
  },
  meta: {
    title: 'mini_html_meeting_search',
    keepAlive: false,
    scrollTop: 0
  }
}, {
  path: '/exhibition-live',
  name: 'ExhibitionLive',
  component: function component() {
    return import('@/views/exhibition/ExhibitionLive');
  },
  meta: {
    title: 'live_list',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/service-information',
  name: 'ServiceInformation',
  component: function component() {
    return import('@/views/exhibition/ServiceInformation');
  },
  meta: {
    title: 'service_guide',
    keepAlive: true,
    appColor: 'FFFFFF',
    scrollTop: 0
  }
}, {
  path: '/service-information-detail',
  name: 'ServiceInformationDetail',
  component: function component() {
    return import('@/views/exhibition/model/ServiceInformationDetail');
  },
  meta: {
    title: 'list_of_service_guidelines',
    keepAlive: true,
    appColor: 'FFFFFF',
    scrollTop: 0,
    scrollClassName: '.list-box'
  }
}, {
  path: '/service-conetnt-details',
  name: 'ServiceConetntDetails',
  component: function component() {
    return import('@/views/exhibition/model/ServiceConetntDetails');
  },
  meta: {
    title: 'service_guide_details',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/exhibits-list',
  name: 'ExhibitsList',
  component: function component() {
    return import('@/views/exhibition/ExhibitsList');
  },
  meta: {
    title: 'exhibit',
    keepAlive: true
  }
}, {
  path: '/business-list',
  name: 'BusinessList',
  component: function component() {
    return import('@/views/exhibition/BusinessList');
  },
  meta: {
    title: 'exhibitors',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.scrooll-bar-none'
  }
}, {
  path: '/downloadFile',
  name: 'DownloadFile',
  component: function component() {
    return import('@/views/exhibition/DownloadFile');
  },
  meta: {
    title: 'data_download',
    keepAlive: false
  }
}, {
  path: '/exhibition-contactUs',
  name: 'ExhibitionContactUs',
  component: function component() {
    return import('@/views/exhibition/ContactUs');
  },
  meta: {
    title: 'router_contact_fj',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/exhibition-notice',
  name: 'ExhibitionNotice',
  component: function component() {
    return import('@/views/exhibition/Notice');
  },
  meta: {
    title: 'commerce_indexNew_noticePublic',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/exhibition-hall',
  name: 'ExhibitionHall',
  component: function component() {
    return import('@/views/exhibition/Hall');
  },
  meta: {
    title: 'exhibition_hall',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/exhibition-hall-detail',
  name: 'ExhibitionHallDetail',
  component: function component() {
    return import('@/views/exhibition/HallDetail');
  },
  meta: {
    title: 'exhibition_hall_details',
    keepAlive: false
  }
}, {
  path: '/stage-hall',
  name: 'StageHall',
  component: function component() {
    return import('@/views/exhibition/StageHall');
  },
  meta: {
    title: 'exhibition_hall',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/multi-party-meeting',
  name: 'MultiPartyMeeting',
  component: function component() {
    return import('@/views/exhibition/MultiPartyMeeting');
  },
  meta: {
    title: 'multi_party_meeting',
    keepAlive: false
  }
}, {
  path: '/marvellous',
  name: 'Marvellous',
  component: function component() {
    return import('@/views/exhibition/Marvellous');
  },
  meta: {
    title: 'wonderful_moment_of_the_delog',
    keepAlive: false
  }
}, {
  path: '/activity-list',
  name: 'ActivityList',
  component: function component() {
    return import('@/views/exhibition/ActivityList');
  },
  meta: {
    title: 'events_list',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/meeting-review',
  name: 'MeetingReview',
  component: function component() {
    return import('@/views/exhibition/meeting/MeetingReview');
  },
  meta: {
    title: 'review',
    keepAlive: false
  }
}, {
  path: '/meeting-review-detail',
  name: 'MeetingReviewDetail',
  component: function component() {
    return import('@/views/exhibition/meeting/MeetingReviewDetail');
  },
  meta: {
    title: 'reviewdetail',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/meeting-regform',
  name: 'MeetingRegForm',
  component: function component() {
    return import('@/views/exhibition/meeting/MeetingRegForm');
  },
  meta: {
    title: 'mini_html_meeting_list',
    keepAlive: false
  }
}, {
  path: '/previous-review',
  name: 'PreviousReview',
  component: function component() {
    return import('@/views/exhibition/model/PreviousReview');
  },
  meta: {
    title: 'previous_exhibitors',
    keepAlive: false
  }
}];