import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.from";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.set";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/*
 * @Descripttion: gm-h5
 * @version: 2.0
 * @Author: Chenck
 * @Date: 2022-03-18 14:58:27
 * @LastEditors: Chenck
 * @LastEditTime: 2023-03-06 23:17:51
 */
import { saveStorage, HISTORY_KEWORD_LIST, HISTORY_CITY, HISTORY_CITY_LANG, CHANNEL_ID, DEFAULT_CITY_NAME, DEFAULT_CITY, DEFAULT_CITY_ID, CITY_TYPE, LANGUAGE, QRCODE, IS_FIRST_ENTRY, NFT_LIST, COUNTRY_ID, OTHERPLATFORMS } from '@/utils/cache';
export var setUserName = function setUserName(_ref, name) {
  var commit = _ref.commit;
  commit('SET_USER_NAME', name);
};
export var setLanguage = function setLanguage(_ref2, language) {
  var commit = _ref2.commit;
  commit('SET_LANGUAGE', language);
  saveStorage(LANGUAGE, language);
}; // 设置历史搜索

export var setHistoryKeywordSearch = function setHistoryKeywordSearch(_ref3, query) {
  var commit = _ref3.commit,
      state = _ref3.state;
  var index = state.historyKeywordList.findIndex(function (item) {
    return item === query;
  });

  if (index > -1) {
    state.historyKeywordList.splice(index, 1);
  }

  var list = JSON.parse(JSON.stringify(Array.from(new Set([query].concat(_toConsumableArray(state.historyKeywordList))))));
  commit('SET_HISTORY_KEWORD_LIST', saveStorage(HISTORY_KEWORD_LIST, list.splice(0, 6)));
}; // 清空历史搜索

export var setHistoryKeywordEmpty = function setHistoryKeywordEmpty(_ref4, query) {
  var commit = _ref4.commit,
      state = _ref4.state;
  commit('SET_HISTORY_KEWORD_LIST', saveStorage(HISTORY_KEWORD_LIST, query));
}; // 修改homepage 页面的动态文章是否固定

export var setHomepageFixed = function setHomepageFixed(_ref5, query) {
  var commit = _ref5.commit,
      state = _ref5.state;
  commit('SET_HOME_PAGE_FIXED', query);
}; // 设置选择成员单位页面的城市历史搜索

export var setHistoryCity = function setHistoryCity(_ref6, query) {
  var commit = _ref6.commit,
      state = _ref6.state;
  var index = state.historyCity.findIndex(function (item) {
    return item.areaId === query.areaId;
  });

  if (index > -1) {
    state.historyCity.splice(index, 1);
  }

  var list = JSON.parse(JSON.stringify(Array.from(new Set([query].concat(_toConsumableArray(state.historyCity))))));
  var lists = list.filter(function (item) {
    return item.lang === state.language;
  });
  commit('SET_HISTORY_CITY', saveStorage(HISTORY_CITY, lists.splice(0, 9)));
}; // 设置历史城市存储语言

export var setHistoryCityLang = function setHistoryCityLang(_ref7, query) {
  var commit = _ref7.commit,
      state = _ref7.state;
  commit('SET_HISTORY_CITY_LANG', saveStorage(HISTORY_CITY_LANG, query));
}; // 清空历史城市数据

export var setHistoryCityEmpty = function setHistoryCityEmpty(_ref8, query) {
  var commit = _ref8.commit,
      state = _ref8.state;
  commit('SET_HISTORY_CITY', saveStorage(HISTORY_CITY, query));
}; // 设置channelId

export var setChannelId = function setChannelId(_ref9, query) {
  var commit = _ref9.commit,
      state = _ref9.state;
  commit('SET_CHANNEL_ID', saveStorage(CHANNEL_ID, query));
}; // 设置境外id

export var setCountryId = function setCountryId(_ref10, query) {
  var commit = _ref10.commit,
      state = _ref10.state;
  commit('SET_COUNTRY_ID', saveStorage(COUNTRY_ID, query));
}; // 设置默认城市

export var setDefaultCity = function setDefaultCity(_ref11, query) {
  var commit = _ref11.commit,
      state = _ref11.state;
  commit('SET_DEFAULT_CITY', saveStorage(DEFAULT_CITY, query));
}; // 设置默认城市ID

export var setDefaultCityId = function setDefaultCityId(_ref12, query) {
  var commit = _ref12.commit,
      state = _ref12.state;
  commit('SET_DEFAULT_CITY_ID', saveStorage(DEFAULT_CITY_ID, query));
}; // 设置走进默认城市名

export var setDefaultCityName = function setDefaultCityName(_ref13, query) {
  var commit = _ref13.commit,
      state = _ref13.state;
  commit('SET_DEFAULT_CITY_NAME', saveStorage(DEFAULT_CITY_NAME, query));
}; // 设置CHANNEL_PARAMS

export var setDefaultCityType = function setDefaultCityType(_ref14, query) {
  var commit = _ref14.commit,
      state = _ref14.state;
  commit('SET_CITY_TYPE', saveStorage(CITY_TYPE, query));
}; // 设置二维码

export var setQrcode = function setQrcode(_ref15, query) {
  var commit = _ref15.commit,
      state = _ref15.state;
  commit('SET_QRCODE', saveStorage(QRCODE, query));
}; // 设置是否首次进入

export var setIsFirstEntry = function setIsFirstEntry(_ref16, query) {
  var commit = _ref16.commit,
      state = _ref16.state;
  commit('IS_FIRST_ENTRY', saveStorage(IS_FIRST_ENTRY, query));
}; // 设置被动任务nft列表

export var setNftList = function setNftList(_ref17, query) {
  var commit = _ref17.commit,
      state = _ref17.state;
  commit('NFT_LIST', saveStorage(NFT_LIST, query));
}; // 设置判断是否是其他平台跳转过来

export var setOTHERPLATFORMS = function setOTHERPLATFORMS(_ref18, query) {
  var commit = _ref18.commit,
      state = _ref18.state;
  commit('OTHERPLATFORMS', saveStorage(OTHERPLATFORMS, query));
}; // 选择群主的信息

export var setSelectGroupOwner = function setSelectGroupOwner(_ref19, query) {
  var commit = _ref19.commit,
      state = _ref19.state;
  commit('SELECT_GROUP_OWNER', query);
};