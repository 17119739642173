import "core-js/modules/es.object.to-string";

/*
 * @Descripttion: h5-ytz
 * @version: 1.0
 * @Author: Luoyy
 * @Date: 2022-03-16 09:56:59
 * @LastEditors: Chenck
 * @LastEditTime: 2022-08-12 18:02:46
 * @PageName: 页面名称
 */
export default [{
  path: '/mypage-follow',
  // 我的关注
  name: 'Follow',
  component: function component() {
    return import('@/views/myPage/Follow');
  },
  meta: {
    title: 'mypage_follow',
    keepAlive: true,
    scrollTop: 0,
    appColor: 'FFFFFF'
  }
}, {
  path: '/mypage-enroll',
  // 报名
  name: 'Enroll',
  component: function component() {
    return import('@/views/myPage/Enroll');
  },
  meta: {
    title: 'mypage_enroll',
    keepAlive: true
  }
}, {
  path: '/mypage-visitor',
  // 访客记录
  name: 'Visitor',
  component: function component() {
    return import('@/views/myPage/Visitor');
  },
  meta: {
    title: 'mypage_visitor',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-realname',
  // 实名认证
  name: 'Realname',
  component: function component() {
    return import('@/views/myPage/Realname');
  },
  meta: {
    title: 'personal_information_certification',
    keepAlive: false
  }
}, {
  path: '/mypage-negotiate',
  // 洽谈记录
  name: 'Negotiate',
  component: function component() {
    return import('@/views/myPage/Negotiate');
  },
  meta: {
    title: 'mypage_negotiate',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-dynamic',
  // 企业动态
  name: 'Dynamic',
  component: function component() {
    return import('@/views/myPage/Dynamic');
  },
  meta: {
    title: 'mypage_dynamic',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-assistant',
  // 数据助手
  name: 'Assistant',
  component: function component() {
    return import('@/views/myPage/Assistant');
  },
  meta: {
    title: 'mypage_assistant',
    keepAlive: true
  }
}, {
  path: '/mypage-collection',
  // 我的收藏
  name: 'MypageCollection',
  component: function component() {
    return import('@/views/myPage/MyCollection');
  },
  meta: {
    title: 'mypage_collection',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-schedule',
  // 我的日程
  name: 'Schedule',
  component: function component() {
    return import('@/views/myPage/Schedule');
  },
  meta: {
    title: 'mypage_schedule',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-inquiry',
  // 询盘订单
  name: 'Inquiry',
  component: function component() {
    return import('@/views/myPage/Inquiry');
  },
  meta: {
    title: 'mypage_inquiry',
    keepAlive: true,
    scrollTop: 0
  }
}, // {
//   path: '/mypage-inquryinfo', // 询盘订单(询盘信息)
//   name: 'InquryInfo',
//   component: () => import('@/views/myPage/model/InquryInfo.vue'),
//   meta: { title: 'mypage_inquryinfo', keepAlive: true }
// },
{
  path: '/work-bench',
  // 展商工作台
  name: 'WorkBench',
  component: function component() {
    return import('@/views/myPage/Workbench.vue');
  },
  meta: {
    title: 'work_bench',
    keepAlive: true
  }
}, {
  path: '/data-board',
  // 数据看板
  name: 'DataBoard',
  component: function component() {
    return import('@/views/myPage/DataBoard.vue');
  },
  meta: {
    title: 'data_board',
    keepAlive: true
  }
}, {
  path: '/spatial-data',
  // 招商空间数据
  name: 'SpatialData',
  component: function component() {
    return import('@/views/myPage/SpatialData.vue');
  },
  meta: {
    title: 'spatial_data',
    keepAlive: true
  }
}, {
  path: '/online-service',
  // 联系我们
  name: 'OnlineService',
  component: function component() {
    return import('@/views/myPage/OnlineService.vue');
  },
  meta: {
    title: 'router_contact_fj',
    keepAlive: true
  }
}, {
  path: '/mypage-certification',
  // 实名认证
  name: 'Certification',
  component: function component() {
    return import('@/views/myPage/Certification');
  },
  meta: {
    title: 'real_name_verification',
    keepAlive: true
  }
}, {
  path: '/mypage-abroad-certification',
  // 境外用户实名认证
  name: 'AbroadCertification',
  component: function component() {
    return import('@/views/myPage/AbroadCertification');
  },
  meta: {
    title: 'mypage_abroad_certification',
    keepAlive: true
  }
}, {
  path: '/mypage-professional',
  // 职业认证
  name: 'Professional',
  component: function component() {
    return import('@/views/myPage/Professional');
  },
  meta: {
    title: 'mypage_professional',
    keepAlive: false
  }
}, {
  path: '/mypage-professional-apply',
  // 职业认证编辑页
  name: 'ProfessionalApply',
  component: function component() {
    return import('@/views/myPage/ProfessionalApply');
  },
  meta: {
    title: 'mypage_professionalApply',
    keepAlive: false
  }
}, {
  path: '/mypage-material',
  // 素材中心
  name: 'Material',
  component: function component() {
    return import('@/views/myPage/Material');
  },
  meta: {
    title: 'mypage_material',
    keepAlive: false
  }
}, {
  path: '/mypage-drafts',
  // 草稿箱
  name: 'Drafts',
  component: function component() {
    return import('@/views/myPage/Drafts');
  },
  meta: {
    title: 'mypage_drafts',
    keepAlive: false
  }
}, {
  path: '/mypage-creative',
  // 创作中心
  name: 'Creative',
  component: function component() {
    return import('@/views/myPage/Creative');
  },
  meta: {
    title: 'mypage_creative',
    keepAlive: false
  }
}, {
  path: '/mypage-creative-search',
  // 创作中心搜索
  name: 'CreativeSearch',
  component: function component() {
    return import('@/views/myPage/CreativeSearch');
  },
  meta: {
    title: 'mypage_creative_search',
    keepAlive: true
  }
}, {
  path: '/mypage-creative-article',
  // 创作中心文章
  name: 'CreativeArticle',
  component: function component() {
    return import('@/views/myPage/CreativeArticle');
  },
  meta: {
    title: 'mypage_creative_article',
    keepAlive: true
  }
}, {
  path: '/mypage-merchants',
  // 招商人员申请
  name: 'Merchants',
  component: function component() {
    return import('@/views/myPage/Merchants');
  },
  meta: {
    title: 'china_merchants_application',
    keepAlive: false
  }
}, {
  path: '/mypage-businessman',
  // 展商申请
  name: 'Businessman',
  component: function component() {
    return import('@/views/myPage/Businessman');
  },
  meta: {
    title: 'exhibitor_application',
    keepAlive: false
  }
}, {
  path: '/mypage-like',
  // 点赞
  name: 'Like',
  component: function component() {
    return import('@/views/myPage/Like');
  },
  meta: {
    title: 'like',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-submitInquiry',
  // 询盘订单
  name: 'MypageSubmitInquiry',
  component: function component() {
    return import('@/views/myPage/SubmitInquiry');
  },
  meta: {
    title: 'mypage_inquiry',
    keepAlive: false
  }
}, {
  path: '/mypage-certificate',
  // 核酸证明
  name: 'Certificate',
  component: function component() {
    return import('@/views/myPage/Certificate');
  },
  meta: {
    title: 'covid_test_report',
    keepAlive: false
  }
}, {
  path: '/mypage-antiepidemic',
  // 防疫管理
  name: 'MypageAntiepidemic',
  component: function component() {
    return import('@/views/myPage/Antiepidemic');
  },
  meta: {
    title: 'epidemic_prevention',
    keepAlive: false
  }
}, {
  path: '/mypage-engine',
  // 商机引擎
  name: 'mypage-engine',
  component: function component() {
    return import('@/views/myPage/Engine');
  },
  meta: {
    title: 'opportunities',
    appColor: '6AB8F4',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-myvisitor',
  // 我的访客
  name: 'mypage-myvisitor',
  component: function component() {
    return import('@/views/myPage/MyVisitor');
  },
  meta: {
    title: 'mypage_visitor',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-registe-info',
  // 报名信息
  name: 'mypage-registeInfo',
  component: function component() {
    return import('@/views/myPage/RegisteInfo');
  },
  meta: {
    title: 'register_information',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/mypage-exhibition-code',
  // 会展码
  name: 'ExhibitionCode',
  component: function component() {
    return import('@/views/myPage/ExhibitionCode');
  },
  meta: {
    title: 'exhibition_code',
    keepAlive: true,
    scrollTop: 0
  }
}];