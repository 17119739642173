require("core-js/modules/es.array.concat");

/*
 * @Author: Chenck
 * @Date: 2022-03-14 15:34:56
 * @LastEditors: Chenck
 * @LastEditTime: 2023-08-24 13:34:47
 * @PageName: 页面名称
 */
// 本地环境配置s
module.exports = {
  title: '',
  baseUrl: 'http://192.168.31.186:9020/',
  // 项目地址
  // baseApi: 'https://zuul.touzicloud.com', // 正式环境 
  // baseApi: 'https://zuul.yunzhantest.top', // 开发环境
  // baseApi: 'https://test-zuul.touzicloud.com', // 测试api请求地址
  baseApi: 'https://zuul.touzicloud.com',
  // 正式api请求地址
  // baseApi: 'http://192.168.31.192:10001', // 国荣
  // baseApi: 'http://192.168.31.192:10001', // 国荣
  // baseApi: '192.168.31.251:10002/user-central/', // 郭 
  // nftBaseApi: 'https://cifitmeta.hundunqishi.com',
  // nftbaseUrl: 'https://cifitmeta.hundunqishi.com',
  nftBaseApi: 'https://api.exhibit.touzicloud.com',
  nftbaseUrl: 'https://h5.exhibit.touzicloud.com',
  // yuanyuzhouUrl: 'https://expo98.yunzhanyun.com',
  yuanyuzhouUrl: 'https://metaverse.touzicloud.com',
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://imgs.solui.cn',
  ossBaseHost: 'b-dev.oss-accelerate.aliyuncs.com',
  ossProcessCJ: function ossProcessCJ(w, h) {
    // 图片处理，缩放裁剪
    return "x-oss-process=image/auto-orient,1/resize,m_fill,w_".concat(w, ",h_").concat(h, "/quality,q_100");
  },
  ossProcesswXh: function ossProcesswXh(w, h) {
    // 图片处理，缩放填充
    return "x-oss-process=image/auto-orient,1/resize,m_pad,w_".concat(w, ",h_").concat(h, "/quality,q_100");
  }
};