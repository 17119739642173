import "core-js/modules/es.array.includes";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.search";
import _typeof from "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/typeof";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import axios from 'axios';
import store from '@/store';
import { loadCookie, removeCookie, saveCookie } from '@/utils/cache';
import { queryURLParameter } from '@/utils/index';
// 根据环境不同引入不同api地址
import { baseApi } from '@/config';
var url = window.location.search;
var params = queryURLParameter(url);
var lang = params['X-Access-Lang'] || store.getters.language;
var langUrl = {
  'zh-cn': "".concat(baseApi, "/"),
  'en-us': "".concat(baseApi, "/")
};
var bApi = langUrl[lang]; // create an axios instance

var service = axios.create({
  baseURL: bApi,
  // url = base api url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout

}); // request拦截器 request interceptor

service.interceptors.request.use(function (config) {
  // 不传递默认开启loading
  if (!config.hideloading) {
    // loading
    _Toast.loading({
      forbidClick: true
    });
  }
  /**
  用于推送资讯的中英文语言切换
  const routeLang = params['lang'] === 'en' ? 'en' : params['lang'] === 'zh' ? 'zh' : ''
  config.headers.lang = routeLang || lang === 'en-us' ? 'en' : 'zh'
  */


  config.headers.lang = lang === 'en-us' ? 'en' : 'zh';

  if (store.getters.token) {
    config.headers['X-Token'] = '';
  } // config.headers.accesstoken = '9e535340-6c73-40c6-9955-7cb3d9046df7'


  if (loadCookie('token', '')) {
    var token = loadCookie('token', '');
    console.log('这是webView的token');
    config.headers.accesstoken = token;
  } // config.headers.accesstoken = '9e535340-6c73-40c6-9955-7cb3d9046df7'


  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // respone拦截器

service.interceptors.response.use(function (response) {
  // 不传递默认开启loading
  if (!response.config.hideloading) {
    _Toast.clear();
  }

  var res = response.data;

  if (res.code && res.code != '200') {
    // 登录超时,重新登录
    if (res.code == '401') {
      store.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    } // 未登录跳转登录


    if (res.code == '30000') {
      try {
        var _url = window.location.href;

        window._wx.miniProgram.navigateTo({
          url: '/pages/auth/auth?url=' + encodeURIComponent(_url)
        });

        var str = navigator.userAgent;

        if (str.includes('YtqApp')) {
          window.flutter_inappwebview.callHandler('YtqApp', 'smsLogin', {
            url: encodeURIComponent(_url),
            type: '2'
          }).then(function (result) {
            console.log(result, _typeof(result));
            console.log(JSON.stringify(result), result);
          }); // YtqApp.postMessage(JSON.stringify({ method: 'smsLogin', data: { url: encodeURIComponent(url) } }));
        }

        if (str.includes('YtqIOSApp')) {
          WebViewJavascriptBridge.callHandler('judgeTokenAction', {
            data: {
              type: '2'
            }
          }, function (dataFromOC) {});
        }
      } catch (_unused) {
        console.log('跳转小程序失败');
      }
    }

    return Promise.reject(res || 'error');
  } else {
    return Promise.resolve(res);
  }
}, function (error) {
  _Toast.clear();

  console.log('err' + error); // for debug

  return Promise.reject(error);
});
export default service;