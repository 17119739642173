import "core-js/modules/es.array.concat";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadCookie } from '@/utils/cache';
import { drawInitiativeTaskNft, drawPassiveTaskNft } from '@/api/nft';
import { nftbaseUrl } from '@/config';
import Vue from 'vue';
Vue.use(_Toast);
export default {
  data: function data() {
    return {
      nftType: 0,
      taskId: null,
      downTime: null,
      token: loadCookie('token'),
      tingleShow: false,
      // 抖动
      info: {
        img: '',
        nftName: '',
        isWin: false
      },
      time: null,
      // 时间
      bagOpen: 0,
      // bagOpen为0则不显示礼包,1是打开礼包,2是礼包为空，3是主动型礼包为空
      count: '' // 倒计时

    };
  },
  methods: {
    init: function init(_ref) {
      var nftType = _ref.nftType,
          taskId = _ref.taskId,
          downTime = _ref.downTime;
      this.nftType = nftType;
      this.taskId = taskId;
      this.downTime = downTime || 0;

      if (this.nftType === 1) {
        downTime ? this.countdownTime() : this.activeTask();
      } else {
        downTime ? this.countdownTime() : this.bagOpen = 1;
      }
    },
    // 倒计时
    countdownTime: function countdownTime() {
      var _this = this;

      this.time = setInterval(function () {
        _this.downTime -= 1; // let d = parseInt(this.downTime / (24 * 60 * 60))
        // d = d < 10 ? '0' + d : d
        // let h = parseInt((this.downTime / (60 * 60)) % 24)
        // h = h < 10 ? '0' + h : h

        var m = parseInt(_this.downTime / 60 % 60);
        m = m < 10 ? '0' + m : m;
        var s = parseInt(_this.downTime % 60);
        s = s < 10 ? '0' + s : s;
        _this.count = m + ':' + s;

        if (_this.downTime == 0) {
          _this.nftType === 1 ? _this.activeTask() : _this.bagOpen = 1;
          _this.time && clearInterval(_this.time);
        }
      }, 1000);
    },
    // 主动任务
    activeTask: function activeTask() {
      var _this2 = this;

      drawInitiativeTaskNft({
        taskId: this.taskId,
        token: this.token
      }).then(function (res) {
        if (parseInt(res.code) !== 200) {
          _Toast.fail(res.msg || _this2.$t('unknown_error'));

          return;
        }

        _this2.downTime = 0;
        _this2.info = res.data;

        if (!_this2.info.isWin) {
          _this2.bagOpen = 3;
        }
      }).catch(function (res) {
        _Toast.fail(res.msg || _this2.$t('unknown_error'));

        _this2.downTime = null;
      });
    },
    // 打开礼包（被动型任务）
    bagBtn: function bagBtn() {
      var _this3 = this;

      this.tingleShow = true;
      drawPassiveTaskNft({
        taskId: this.taskId,
        token: this.token
      }).then(function (res) {
        if (parseInt(res.code) !== 200) {
          _Toast.fail(res.msg || _this3.$t('unknown_error'));

          return;
        }

        setTimeout(function () {
          _this3.info = res.data;

          if (!_this3.info.isWin) {
            _this3.bagOpen = 2;
          }
        }, 1000);
      }).catch(function (res) {
        _Toast.fail(res.msg || _this3.$t('unknown_error'));

        _this3.downTime = null;
        _this3.bagOpen = 0;
      });
    },
    // 跳转我的展品
    goUrl: function goUrl() {
      window.location.href = "".concat(nftbaseUrl, "/user/digital-collection?token=").concat(this.token);
    },
    // 关闭nft 页面
    closeBtn: function closeBtn() {
      this.bagOpen = 0;
      this.tingleShow = false;
      this.downTime = null;
      this.count = '';
      this.$emit('close');
    }
  }
};