import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";

/**
 *@name 全局工具
 *@param { String } time
 *@returns { String }
 */
import i18n from '@/assets/i18n/i18n';
export default {
  /** ************ 时间转换成新闻资讯时间 ****************/
  transformDate: function transformDate(value, day) {
    if (!value) return '';
    var timer = value.split(' ');
    var date = timer[1];
    var time1 = new Date(value);
    var d = time1.getTime();
    var now = Date.now();
    var diff = (now - d) / 1000;

    if (diff < 3600) {
      // less 1 hour
      return i18n.t('just');
    } else if (diff < 3600 * 24) {
      return Math.ceil(diff / 3600) + i18n.t('an_hour_ago');
    } else if (diff < 3600 * 24 * 2) {
      return i18n.t('day_ago');
    } else if (diff < 3600 * 24 * 3) {
      return '2' + i18n.t('days_ago2');
    } else if (diff < 3600 * 24 * 4) {
      return '3' + i18n.t('days_ago2');
    } else if (diff >= 3600 * 24 * 4) {
      return day === 'dateTime' ? value : day ? timer[0] : date;
    }
  }
};