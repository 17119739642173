import "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.array.iterator.js";
import "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.js";
import "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.object.assign.js";
import "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.finally.js";

/*
 * @Descripttion: h5-ytz
 * @version: 1.0
 * @Author: Luoyy
 * @Date: 2022-03-21 09:25:34
 * @LastEditors: Chenck
 * @LastEditTime: 2023-01-09 11:45:42
 * @PageName: 页面名称
 */
// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Env } from '@/utils/platform';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Video from 'video.js';
import i18n from './assets/i18n/i18n';
import 'video.js/dist/video-js.css';
import * as echarts from 'echarts'; // 设置 js中可以访问 $cdn

import { $cdn } from '@/config';
import waterfall from 'vue-waterfall2';
import common from './utils/common.js';
import Vconsole from 'vconsole';

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
  Vue.prototype.$vConsole = new Vconsole();
}

Vue.prototype.$common = common;
Vue.use(waterfall);
Vue.prototype.$cdn = $cdn;
Vue.prototype.$video = Video;
Vue.prototype.$echarts = echarts; // 全局挂载安卓、IOS交互事件

import Bridge from './utils/JSbridge.js';
Vue.prototype.$bridge = Bridge;
import { YtqAndroidApp, YtqIOSApp, AllShareInfo } from '@/utils/ytqAndroid.js';
Vue.prototype.$ytqAndroidApp = YtqAndroidApp;
Vue.prototype.$ytqIOSApp = YtqIOSApp;
Vue.prototype.$AllShareInfo = AllShareInfo;
import { publicMixin } from './utils/mixin';
Vue.mixin(publicMixin); // 定义一个全局组件

import AndroidAppBar from '@/components/AndroidAppBar.vue';
Vue.component('AndroidAppBar', AndroidAppBar);
import NotData from '@/components/NotData.vue';
Vue.component('NotData', NotData);
Vue.config.ignoredElements = ['wx-open-launch-weapp']; // 全局挂载vue左滑右滑事件

import VueTouch from 'vue-touch';
Vue.use(VueTouch, {
  name: 'v-touch'
}); // 全局引入按需引入UI库 vant

import '@/plugins/vant'; // 桌面端适配

import '@vant/touch-emulator'; // 引入全局样式

import '@/assets/css/index.scss'; // 移动端适配

import 'lib-flexible/flexible.js';
import VueClipBoard from 'vue-clipboard2';
VueClipBoard.config.autoSetContainer = true;
Vue.use(VueClipBoard); // filters

import './filters';
Vue.config.productionTip = false;
router.afterEach(function (to, from) {
  if (to.meta.title || to.query.title) {
    var title = to.query.title || to.meta.title;
    document.title = i18n.t(title);
  }

  if (Env.isIOSApp()) {
    setTimeout(function () {
      if (to.meta.appColor) {
        WebViewJavascriptBridge.callHandler('navColorAction', {
          data: {
            colorStr: to.meta.appColor
          }
        }, function (dataFromOC) {});
      } else {
        WebViewJavascriptBridge.callHandler('navColorAction', {
          data: {
            colorStr: 'EF2D2D'
          }
        }, function (dataFromOC) {});
      }
    }, 100);
  }

  if (Env.isAndroidApp()) {
    setTimeout(function () {
      if (to.meta.appColor) {
        YtqAndroidApp.appBarBackground(to.meta.appColor);
      } else {
        YtqAndroidApp.appBarBackground();
      }
    }, 100);
  }

  if (to.meta.keepAlive && (to.meta.scrollTop || to.meta.scrollTop === 0)) {
    setTimeout(function () {
      var scrollTop = to.meta.scrollTop;
      var $wrapper = document.querySelector(to.meta.scrollClassName || '#app');

      if (scrollTop && $wrapper) {
        $wrapper.scrollTop = scrollTop;
      }
    }, 100);
  }
});
router.beforeEach(function (to, from, next) {
  if (from.meta.keepAlive && (from.meta.scrollTop || from.meta.scrollTop === 0)) {
    var $wrapper = document.querySelector(from.meta.scrollClassName || '#app'); // 列表的外层容器 注意找到滚动的盒子

    var scrollTop = $wrapper ? $wrapper.scrollTop : 0;
    console.log(scrollTop, '22');
    from.meta.scrollTop = scrollTop;
  }

  next();
});
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
});