var area = localStorage.getItem('area');
var isFirstLoad = localStorage.getItem('isFirstLoad');
export default {
  namespaced: true,
  state: {
    area: area ? JSON.parse(area) : {
      name: '',
      id: ''
    },
    isFirstLoad: isFirstLoad !== 'false'
  },
  mutations: {
    SET_AREA: function SET_AREA(state, area) {
      state.area = area;
      localStorage.setItem('area', JSON.stringify(area) || '{}');
    },
    SET_FIRSTLOAD: function SET_FIRSTLOAD(state, isFirstLoad) {
      state.isFirstLoad = isFirstLoad;
      localStorage.setItem('isFirstLoad', isFirstLoad);
    }
  },
  actions: {}
};