import "core-js/modules/es.object.to-string";
// 首页投洽会
export default [{
  path: '/introduce',
  // 投洽会介绍
  name: 'Introduce',
  component: function component() {
    return import('@/views/cifit/Introduce');
  },
  meta: {
    title: 'cifit_Introduce',
    keepAlive: false
  }
}, {
  path: '/handbook',
  // 参会指南
  name: 'Handbook',
  component: function component() {
    return import('@/views/cifit/Handbook');
  },
  meta: {
    title: 'cifit_Handbook',
    keepAlive: true,
    appColor: 'FFFFFF'
  }
}, {
  path: '/zjdt',
  // 参会指南-证件地图
  name: 'Zjdt',
  component: function component() {
    return import('@/views/cifit/Zjdt');
  },
  meta: {
    title: 'cifit_Zjdt',
    keepAlive: false,
    appColor: '0F1870'
  }
}, {
  path: '/antiepidemic',
  // 参会指南-展会防疫
  name: 'Antiepidemic',
  component: function component() {
    return import('@/views/cifit/Antiepidemic');
  },
  meta: {
    title: 'cifit_Antiepidemic',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/exhibitionGuideList',
  // 展商指南
  name: 'ExhibitionGuideList',
  component: function component() {
    return import('@/views/cifit/ExhibitionGuideList');
  },
  meta: {
    title: 'cifit_ExhibitionGuideList',
    keepAlive: false
  }
}, {
  path: '/exhibitionGuideDetail',
  // 展商指南-详情
  name: 'ExhibitionGuideDetail',
  component: function component() {
    return import('@/views/cifit/components/ExhibitionGuide');
  },
  meta: {
    title: 'cifit_ExhibitionGuideDetail',
    keepAlive: false
  }
}, {
  path: '/meetingForum',
  // 会议论坛集锦
  name: 'MeetingForum',
  component: function component() {
    return import('@/views/cifit/MeetingForum');
  },
  meta: {
    title: '',
    keepAlive: true,
    appColor: 'EF2D2D',
    scrollTop: 0
  }
}, {
  path: '/meetingForumVideo',
  // 会议论坛集锦-详情
  name: 'MeetingForumVideo',
  component: function component() {
    return import('@/views/cifit/components/MeetingForumVideo');
  },
  meta: {
    title: 'video_details',
    keepAlive: false,
    appColor: '0C29EE'
  }
}, {
  path: '/memberUnit',
  // 成员单位
  name: 'MemberUnit',
  component: function component() {
    return import('@/views/cifit/MemberUnit');
  },
  meta: {
    title: 'cifit_MemberUnit',
    keepAlive: true,
    appColor: 'FFFFFF',
    scrollTop: 0
  }
}, {
  path: '/memberCityDetail',
  // 成员单位-详情
  name: 'MemberCityDetail',
  component: function component() {
    return import('@/views/cifit/components/MemberCityDetail');
  },
  meta: {
    title: 'cifit_MemberCityDetail',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/member-project-list',
  // 项目列表
  name: 'MemberProjectList',
  component: function component() {
    return import('@/views/cifit/model/ProjectList');
  },
  meta: {
    title: 'cifit_MemberCityProjectList',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/cifit/richDetail',
  // 公共详情
  name: 'cifitRichDetail',
  component: function component() {
    return import('@/views/cifit/model/RichDetail');
  },
  meta: {
    title: 'router_article_detail',
    keepAlive: false
  }
}, {
  path: '/cifit/noticeNews',
  // 公告
  name: 'NoticeNews',
  component: function component() {
    return import('@/views/cifit/NoticeNews');
  },
  meta: {
    title: 'mini_html_antiepidemic',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/participationGuide',
  name: 'participationGuide',
  component: function component() {
    return import('@/views/cifit/ParticipationGuide');
  },
  meta: {
    title: 'exhibition_service',
    keepAlive: false
  }
}, {
  path: '/participationGuideDetail',
  name: 'participationGuideDetail',
  component: function component() {
    return import('@/views/cifit/ParticipationGuideDetail');
  },
  meta: {
    title: 'exhibition_service',
    keepAlive: false
  }
}, {
  path: '/hotelOrder',
  name: 'hotelOrder',
  component: function component() {
    return import('@/views/cifit/HotelOrder');
  },
  meta: {
    title: 'exhibition_service',
    keepAlive: false
  }
}, {
  path: '/invitationCard',
  name: 'InvitationCard',
  component: function component() {
    return import('@/views/cifit/InvitationCard');
  },
  meta: {
    title: '邀请函',
    keepAlive: false
  }
}, {
  path: '/middle-page',
  name: 'MiddlePage',
  component: function component() {
    return import('@/views/cifit/MiddlePage');
  },
  meta: {
    title: '投洽会元宇宙',
    keepAlive: false
  }
}, {
  path: '/test-open-wxmini',
  name: 'testOpenWxMini',
  component: function component() {
    return import('@/views/cifit/testOpenWxMini');
  },
  meta: {
    title: '测试打开微信小程序',
    keepAlive: false
  }
}, {
  path: '/essence-speaker',
  name: 'EssenceSpeaker',
  component: function component() {
    return import('@/views/cifit/EssenceSpeaker');
  },
  meta: {
    title: 'spokesperson',
    keepAlive: false
  }
}, {
  path: '/guidelines',
  name: 'Guidelines',
  component: function component() {
    return import('@/views/cifit/Guidelines');
  },
  meta: {
    title: '入馆指引',
    keepAlive: false
  }
}, {
  path: '/honor-list',
  name: 'HonorList',
  component: function component() {
    return import('@/views/cifit/HonorList');
  },
  meta: {
    title: '主宾国、主宾省',
    keepAlive: false
  }
}];