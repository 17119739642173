import { Env } from '@/utils/platform';
export default {
  // APP调js方法 （参数分别为:js提供的方法名  回调）
  registerHandler: function registerHandler(name, callback) {
    if (Env.isIOSApp()) {
      setupWebViewJavascriptBridge(function (bridge) {
        bridge.registerHandler(name, callback);
      });
    } else {
      return;
    }
  }
};