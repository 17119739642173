import "core-js/modules/es.array.includes";
import "core-js/modules/es.string.includes";

/*
 * @Author: Chenck
 * @Date: 2022-03-17 15:04:49
 * @LastEditors: Chenck
 * @LastEditTime: 2022-07-30 17:02:50
 * @PageName: 页面名称
 */

/**
 * 判断h5的运行环境
 * */
export var Env = {
  isAndroid: function isAndroid() {
    return /[a|A]ndroid/i.test(navigator.userAgent);
  },
  isIOS: function isIOS() {
    return /iPhone|iPad|iPod|iOS/i.test(navigator.userAgent);
  },
  isMobile: function isMobile() {
    return this.isIOS() || this.isAndroid();
  },
  isWeixin: function isWeixin() {
    return navigator.userAgent.includes('MicroMessenger');
  },
  isMini: function isMini() {
    return /miniProgram/i.test(navigator.userAgent.toLowerCase());
  },
  isAndroidApp: function isAndroidApp() {
    return navigator.userAgent.includes('YtqApp');
  },
  isIOSApp: function isIOSApp() {
    return navigator.userAgent.includes('YtqIOSApp');
  },
  isYtqApp: function isYtqApp() {
    return this.isIOSApp() || this.isAndroidApp();
  }
};