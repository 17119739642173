require("core-js/modules/es.array.concat");

/*
 * @Author: Chenck
 * @Date: 2022-03-14 15:34:56
 * @LastEditors: Chenck
 * @LastEditTime: 2023-04-20 18:56:29
 * @PageName: 页面名称
 */
// 正式
module.exports = {
  title: '',
  baseUrl: 'https://h5.yunzhan.touzicloud.com/',
  // 正式项目地址
  baseApi: 'https://zuul.touzicloud.com',
  // 正式api请求地址
  nftBaseApi: 'https://api.exhibit.touzicloud.com',
  nftbaseUrl: 'https://h5.exhibit.touzicloud.com',
  yuanyuzhouUrl: 'https://metaverse.touzicloud.com',
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://imgs.solui.cn',
  ossBaseHost: 'biz-prod.oss-accelerate.aliyuncs.com',
  ossProcessCJ: function ossProcessCJ(w, h) {
    return "x-oss-process=image/auto-orient,1/resize,m_fill,w_".concat(w, ",h_").concat(h, "/quality,q_100");
  },
  ossProcesswXh: function ossProcesswXh(w, h) {
    return "x-oss-process=image/auto-orient,1/resize,m_pad,w_".concat(w, ",h_").concat(h, "/quality,q_100");
  }
};