import "core-js/modules/es.object.to-string";

/*
 * @Author: Chenck
 * @Date: 2022-03-14 15:34:56
 * @LastEditors: Chenck
 * @LastEditTime: 2023-03-06 23:11:46
 * @PageName: 页面名称
 */
// 首页投洽会
export default [{
  path: '/projectList',
  name: 'projectList',
  component: function component() {
    return import('@/views/common/ProjectList');
  },
  meta: {
    title: 'commerce_indexNew_investProject',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/projectDetail',
  name: 'ProjectDetail',
  component: function component() {
    return import('@/views/common/ProjectDetail');
  },
  meta: {
    title: 'overseas_exampleDetail_productDetail',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/richDetail',
  name: 'RichDetail',
  component: function component() {
    return import('@/views/common/RichDetail');
  },
  meta: {
    title: 'router_article_detail',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/yaokeyuyue',
  name: 'Yaokeyuyue',
  component: function component() {
    return import('@/views/common/Yaokeyuyue');
  },
  meta: {
    title: 'please_make_an_appointment_for_an_appointment',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/videoList',
  name: 'VideoList',
  component: function component() {
    return import('@/views/common/VideoList');
  },
  meta: {
    title: 'video_list',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/video-player',
  // 走进投洽会视频播放页面
  name: 'VideoPlayer',
  component: function component() {
    return import('@/views/common/VideoPlayer');
  },
  meta: {
    title: 'video_details',
    keepAlive: false,
    appColor: '0F1870'
  }
}, {
  path: '/video-play-page',
  // 公用视频播放页
  name: 'VideoPlayPage',
  component: function component() {
    return import('@/views/common/VideoPlayPage');
  },
  meta: {
    title: 'video_details',
    keepAlive: false,
    appColor: '0F1870'
  }
}, {
  path: '/parkList',
  // 园区列表
  name: 'ParkList',
  component: function component() {
    return import('@/views/common/ParkList');
  },
  meta: {
    title: 'location_for_investment',
    keepAlive: true,
    appColor: 'EF2D2D',
    scrollTop: 0,
    scrollClassName: '.list-wrap'
  }
}, {
  path: '/parkDetail',
  name: 'ParkDetail',
  component: function component() {
    return import('@/views/common/ParkDetail');
  },
  meta: {
    title: 'router_parkDetail',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/newsDetails',
  name: 'NewsDetails',
  component: function component() {
    return import('@/views/common/NewsDetails');
  },
  meta: {
    title: '',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/vip-video-list',
  name: 'VipVideoList',
  component: function component() {
    return import('@/views/common/VipVideoList');
  },
  meta: {
    title: 'spokesperson',
    keepAlive: true
  }
}, {
  path: '/vip-video-homepage',
  name: 'VipVideoHomePage',
  component: function component() {
    return import('@/views/common/VipVideoHomePage');
  },
  meta: {
    title: 'speaker_homepage',
    keepAlive: false
  }
}, {
  path: '/vip-video-detail',
  name: 'VipVideoDetail',
  component: function component() {
    return import('@/views/common/VipVideoDetail');
  },
  meta: {
    title: 'lecturer_s_essence_details',
    keepAlive: false
  }
}, {
  path: '/protocol',
  name: 'Protocol',
  component: function component() {
    return import('@/views/common/Protocol');
  },
  meta: {
    title: '',
    keepAlive: true
  }
}, {
  path: '/verified-confirm',
  name: 'VerifiedConfirm',
  component: function component() {
    return import('@/views/common/VerifiedConfirm');
  },
  meta: {
    title: 'verified_confirm.title',
    keepAlive: true
  }
}, {
  path: '/product-list',
  name: 'ProductList',
  component: function component() {
    return import('@/views/common/ProductList');
  },
  meta: {
    title: 'product_list',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/down-view',
  name: 'DownView',
  component: function component() {
    return import('@/views/common/DownView');
  },
  meta: {
    title: 'DownView',
    keepAlive: false
  }
}, {
  path: '/download-records',
  name: 'DownloadRecords',
  component: function component() {
    return import('@/views/common/DownloadRecords');
  },
  meta: {
    title: 'downloadrecords',
    keepAlive: false
  }
}, {
  path: '/long-video-list',
  name: 'LongVideoList',
  component: function component() {
    return import('@/views/common/LongVideoList');
  },
  meta: {
    title: 'wonderful_video',
    keepAlive: false
  }
}, {
  path: '/project-group-list',
  name: 'ProjectGroupList',
  component: function component() {
    return import('@/views/common/projectGroup/ProjectGroupList');
  },
  meta: {
    title: '项目群聊',
    keepAlive: false
  }
}, {
  path: '/create-group-chat',
  name: 'CreateGroupChat',
  component: function component() {
    return import('@/views/common/projectGroup/CreateGroupChat');
  },
  meta: {
    title: '新建群聊',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.create-group'
  }
}, {
  path: '/select-group-owner',
  name: 'SelectGroupOwner',
  component: function component() {
    return import('@/views/common/projectGroup/SelectGroupOwner');
  },
  meta: {
    title: '选择群主',
    keepAlive: false
  }
}, {
  path: '/file-pdf',
  name: 'FilesPDF',
  component: function component() {
    return import('@/views/common/FilesPDF');
  },
  meta: {
    title: 'filepreview',
    keepAlive: false
  }
}];