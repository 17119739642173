import "vant/es/empty/style";
import _Empty from "vant/es/empty";
//
//
//
//
//
import i18n from '@/assets/i18n/i18n';
import Vue from 'vue';
Vue.use(_Empty);
export default {
  props: {
    img: {
      type: String,
      default: 'https://exhibition-cloud.oss-cn-hangzhou.aliyuncs.com/h5-statics/empty-material.png'
    },
    title: {
      type: String,
      default: i18n.t('enter_all_noData')
    }
  },
  data: function data() {
    return {};
  }
};