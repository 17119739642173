import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.search";
import "core-js/modules/es.string.split";
import _typeof from "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/typeof";

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
} // 格式化url参数

export var queryURLParameter = function queryURLParameter(url) {
  var reg = /([^&?=]+)=([^&?=]+)/g;
  var obj = {};
  url.replace(reg, function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    obj[args[1]] = args[2];
  });
  return obj;
}; // 获取url中的某个参数值，传入url与参数名

export function getUrlQuery(url, key) {
  var arr = url.split('?');
  var vars = arr[1].split('&');

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');

    if (pair[0] == key) {
      return pair[1];
    }
  }

  return false;
} //删除url中key为name的对应键值对参数，并返回剩下的url  

export function delUrlByName(name) {
  var loca = window.location;
  var baseUrl = loca.origin + loca.pathname;
  var query = loca.search.split('?')[1];

  if (!query) {
    return window.location.href;
  }

  if (loca.href.indexOf(name) > -1) {
    var obj = {};
    var arr = query.indexOf('&') > -1 ? query.split('&') : [query]; //根据&分割，这里的service是unicode编码过得，不存在内部出现&的情况（会被转义成%）

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split("=");
      obj[arr[i][0]] = arr[i][1];
    }

    ;
    delete obj[name];
    var str = JSON.stringify(obj) === '{}' ? "" : "?";
    var url = baseUrl + str + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
    debugger;
    return url;
  } else {
    return window.location.href;
  }
} // 获取唯一标识

var S4 = function S4() {
  return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
};

export function guid() {
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
} // 获取当前时间

export function FormatDate() {
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth();
  month = month + 1 > 12 ? 1 : month + 1;
  month = month > 9 ? month : '0' + month.toString();
  var day = d.getDate();
  day = day > 9 ? day : '0' + day.toString();
  var hour = d.getHours();
  hour = hour > 9 ? hour : '0' + hour.toString();
  var minute = d.getMinutes();
  minute = minute > 9 ? minute : '0' + minute.toString();
  var second = d.getSeconds();
  second = second > 9 ? second : '0' + second.toString();
  return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
}