import "core-js/modules/es.object.to-string";

/*
 * @Descripttion: h5-ytz
 * @version: 1.0
 * @Author: Chenck
 * @Date: 2022-03-17 14:39:50
 * @LastEditors: Chenck
 * @LastEditTime: 2022-07-27 10:45:32
 * @PageName: 页面名称
 */
export default [{
  path: '/index-search',
  // 搜索
  name: 'IndexSearch',
  component: function component() {
    return import('@/views/appIndex/Search');
  },
  meta: {
    title: 'search_title',
    keepAlive: true,
    appColor: 'FFFFFF',
    scrollTop: 0,
    scrollClassName: '.list-wrap'
  }
}, {
  path: '/mini-search',
  // 小程序搜索
  name: 'MiniSearch',
  component: function component() {
    return import('@/views/appIndex/MiniSearch');
  },
  meta: {
    title: 'search_title',
    keepAlive: true,
    appColor: 'FFFFFF',
    scrollTop: 0,
    scrollClassName: '.list-wrap'
  }
}, {
  path: '/homepage',
  // 个人主页
  name: 'Homepage',
  component: function component() {
    return import('@/views/appIndex/Homepage');
  },
  meta: {
    title: 'my_profile',
    keepAlive: false
  }
}, {
  path: '/scan-result-friend',
  name: 'ScanResultFriend',
  component: function component() {
    return import('@/views/appIndex/ScanResultFriend');
  },
  meta: {
    title: 'scanning_result',
    keepAlive: false
  }
}, {
  path: '/region',
  name: 'Region',
  component: function component() {
    return import('@/views/appIndex/Region');
  },
  meta: {
    title: 'switch_member_unit',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}];