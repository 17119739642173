var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "blank-black" }),
    _c(
      "div",
      {
        staticClass: "app-bar-component",
        style: { backgroundColor: _vm.backgroundColor }
      },
      [
        _c("img", {
          staticClass: "img-1",
          attrs: {
            src: _vm.whiteIcon
              ? require("../assets/images/appIndex/white-left-icon.svg")
              : require("../assets/images/appIndex/back-icon.svg"),
            alt: ""
          },
          on: {
            click: function($event) {
              return _vm.closeHandle()
            }
          }
        }),
        _c(
          "div",
          { staticClass: "app-title ellipsis", style: { color: _vm.color } },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _c("img", {
          staticClass: "img-2",
          attrs: {
            src: _vm.whiteIcon
              ? require("../assets/images/appIndex/white-more-icon.svg")
              : require("../assets/images/appIndex/more-icon.svg"),
            alt: ""
          },
          on: {
            click: function($event) {
              return _vm.shareHandle()
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }