import "core-js/modules/es.object.to-string";
// 园区推荐
export default [{
  path: '/park',
  name: 'Park',
  component: function component() {
    return import('@/views/recommend/Park');
  },
  meta: {
    title: 'park_recommendation',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/apply',
  name: 'Apply',
  component: function component() {
    return import('@/views/recommend/model/Apply');
  },
  meta: {
    title: 'application_for_park_road_show',
    keepAlive: false
  }
}, {
  path: '/hot/parkList',
  name: 'HotParkList',
  component: function component() {
    return import('@/views/recommend/model/ParkList');
  },
  meta: {
    title: 'popular_park',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/hot/projectList',
  name: 'HotProjectListt',
  component: function component() {
    return import('@/views/recommend/model/ProjectList');
  },
  meta: {
    title: 'list_of_investment_opportunities',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/industry',
  name: 'Industry',
  component: function component() {
    return import('@/views/recommend/Industry');
  },
  meta: {
    title: 'industrial_think_tank',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/industy-news',
  name: 'IndustyNews',
  component: function component() {
    return import('@/views/recommend/model/IndustyNews');
  },
  meta: {
    title: 'industrial_information',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/roadshow',
  name: 'Roadshow',
  component: function component() {
    return import('@/views/recommend/Roadshow');
  },
  meta: {
    title: 'project_docking_activity',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/docking-activity',
  name: 'DockingActivity',
  component: function component() {
    return import('@/views/recommend/model/DockingActivity');
  },
  meta: {
    title: 'list_of_project_docking_activities',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}];