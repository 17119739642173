import "core-js/modules/es.number.constructor";

/*
 * @Descripttion: gm-h5
 * @version: 2.0
 * @Author: Chenck
 * @Date: 2022-03-18 14:57:24
 * @LastEditors: Chenck
 * @LastEditTime: 2023-03-06 23:15:11
 */
import { loadStorage, LANGUAGE, DEFAULT_CITY, DEFAULT_CITY_ID, HISTORY_KEWORD_LIST, HISTORY_CITY, HISTORY_CITY_LANG, CHANNEL_ID, DEFAULT_CITY_NAME, CITY_TYPE, QRCODE, IS_FIRST_ENTRY, NFT_LIST, COUNTRY_ID, OTHERPLATFORMS } from '@/utils/cache';
import { Env } from '../utils/platform';
var state = {
  userName: '',
  // language: 'zh-cn',
  historyKeywordList: loadStorage(HISTORY_KEWORD_LIST, []),
  homepageFixed: false,
  language: loadStorage(LANGUAGE, 'zh-cn'),
  historyCityLang: loadStorage(HISTORY_CITY_LANG, 'zh-cn'),
  areaName: loadStorage(DEFAULT_CITY, '厦门市'),
  areaId: loadStorage(DEFAULT_CITY_ID, '350200'),
  channelId: loadStorage(CHANNEL_ID, ''),
  historyCity: loadStorage(HISTORY_CITY, []),
  defaultCityName: loadStorage(DEFAULT_CITY_NAME, ''),
  cityType: Number(loadStorage(CITY_TYPE, 1)),
  countryId: loadStorage(COUNTRY_ID, ''),
  isAndroid: Env.isAndroidApp(),
  isIOSApp: Env.isIOSApp(),
  isYtqApp: Env.isYtqApp(),
  isMini: Env.isMini(),
  qrcode: loadStorage(QRCODE, ''),
  isFirstEntry: loadStorage(IS_FIRST_ENTRY, false),
  nftList: loadStorage(NFT_LIST, []),
  otherPlatforms: loadStorage(OTHERPLATFORMS, '') || '',
  selectGroupOwner: null
};
export default state;