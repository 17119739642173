import "core-js/modules/es.object.to-string";
// 首页
export default [{
  path: '/hot-city-index',
  name: 'HotCityIndex',
  component: function component() {
    return import('@/views/enter/HotCityIndex');
  },
  meta: {
    title: 'investment_hotspot_cities',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/charm-city',
  name: 'CharmCity',
  component: function component() {
    return import('@/views/home/CharmCity');
  },
  meta: {
    title: 'charm_city',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/cluster',
  name: 'Cluster',
  component: function component() {
    return import('@/views/home/Cluster');
  },
  meta: {
    title: 'industrial_cluster',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/clusterDetail',
  name: 'ClusterDetail',
  component: function component() {
    return import('@/views/home/model/ClusterDetail');
  },
  meta: {
    title: 'overseas_advantageIndustry_industryDetail',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/investmentMap',
  name: 'InvestmentMap',
  component: function component() {
    return import('@/views/home/InvestmentMap');
  },
  meta: {
    title: 'investment_map',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/policy',
  name: 'Policy',
  component: function component() {
    return import('@/views/home/Policy');
  },
  meta: {
    title: 'commerce_lowpolicy',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.content'
  }
}, {
  path: '/services',
  name: 'Services',
  component: function component() {
    return import('@/views/home/Services');
  },
  meta: {
    title: 'xiamen_investment_services',
    keepAlive: false,
    appColor: 'FFFFFF',
    scrollTop: 0
  }
}, {
  path: '/servicesDetail',
  name: 'ServicesDetail',
  component: function component() {
    return import('@/views/home/model/ServicesDetail');
  },
  meta: {
    title: 'router_activityDetail',
    keepAlive: false,
    appColor: 'EF2D2D'
  }
}, {
  path: '/contact',
  name: 'Contact',
  component: function component() {
    return import('@/views/home/Contact');
  },
  meta: {
    title: 'router_contact_fj',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/microNews',
  name: 'MicroNews',
  component: function component() {
    return import('@/views/home/MicroNews');
  },
  meta: {
    title: 'investment_micro_news',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/manual',
  name: 'Manual',
  component: function component() {
    return import('@/views/home/Manual');
  },
  meta: {
    title: 'investment_manual',
    keepAlive: false
  }
}, {
  path: '/enterprise',
  name: 'Enterprise',
  component: function component() {
    return import('@/views/home/Enterprise');
  },
  meta: {
    title: 'famous_enterprise_window',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/businessIndex',
  name: 'BusinessIndex',
  component: function component() {
    return import('@/views/home/BusinessIndex');
  },
  meta: {
    title: 'corporate_micro_official_website',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/businessIntroduce',
  name: 'BusinessIntroduce',
  component: function component() {
    return import('@/views/home/model/BusinessIntroduce');
  },
  meta: {
    title: 'enter_companyDetail_enterpriseIntroduction',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/businessProduct',
  name: 'BusinessProduct',
  component: function component() {
    return import('@/views/home/model/BusinessProduct');
  },
  meta: {
    title: 'product_introduction',
    keepAlive: true,
    appColor: 'FFFFFF'
  }
}, {
  path: '/businessProductDetail',
  name: 'BusinessProductDetail',
  component: function component() {
    return import('@/views/home/model/BusinessProductDetail');
  },
  meta: {
    title: 'router_productDetail',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/submitInquiry',
  name: 'SubmitInquiry',
  component: function component() {
    return import('@/views/home/model/SubmitInquiry');
  },
  meta: {
    title: 'submit_inquiry',
    keepAlive: false
  }
}, {
  path: '/businessProject',
  name: 'BusinessProject',
  component: function component() {
    return import('@/views/home/model/BusinessProject');
  },
  meta: {
    title: 'project_detail_intro',
    keepAlive: false
  }
}, {
  path: '/businessExhibition',
  name: 'BusinessExhibition',
  component: function component() {
    return import('@/views/home/model/BusinessExhibition');
  },
  meta: {
    title: 'enterprise_exhibiting_platform',
    keepAlive: false
  }
}, {
  path: '/exhibitionDetail',
  name: 'ExhibitionDetail',
  component: function component() {
    return import('@/views/home/model/ExhibitionDetail');
  },
  meta: {
    title: 'exhibition_details',
    keepAlive: true
  }
}, {
  path: '/exhibitionBooth',
  name: 'ExhibitionBooth',
  component: function component() {
    return import('@/views/home/model/ExhibitionBooth');
  },
  meta: {
    title: 'introduction_to_the_booth',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/businessNews',
  name: 'BusinessNews',
  component: function component() {
    return import('@/views/home/model/BusinessNews');
  },
  meta: {
    title: ' ',
    keepAlive: true
  }
}, {
  path: '/enterpriseAtlas',
  name: 'EnterpriseAtlas',
  component: function component() {
    return import('@/views/home/model/EnterpriseAtlas');
  },
  meta: {
    title: 'enterprise_album',
    keepAlive: true,
    appColor: 'FFFFFF'
  }
}, {
  path: '/contactUs',
  name: 'ContactUs',
  component: function component() {
    return import('@/views/home/model/ContactUs');
  },
  meta: {
    title: 'router_contact_fj',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/exhibitionsignup',
  name: 'ExhibitionSignup',
  component: function component() {
    return import('@/views/home/ExhibitionSignup');
  },
  meta: {
    title: 'exhibition_registration',
    keepAlive: false
  }
}, {
  path: '/metaverse',
  name: 'Metaverse',
  component: function component() {
    return import('@/views/home/Metaverse');
  },
  meta: {
    title: 'metaverse',
    keepAlive: false
  }
}, {
  path: '/stage-hall-anual',
  name: 'StageHallManual',
  component: function component() {
    return import('@/views/enter/components/StageHallManual');
  },
  meta: {
    title: 'exhibition_hall',
    keepAlive: true,
    scrollTop: 0
  }
}];