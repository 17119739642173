import "core-js/modules/es.array.reduce";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import getters from './getters';
import * as actions from './actions';
import mutations from './mutations';
Vue.use(Vuex); // https://webpack.js.org/guides/dependency-management/#requirecontext

var modulesFiles = require.context('./modules', true, /\.js$/); // you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file


var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  modules: modules,
  getters: getters
});
export default store;