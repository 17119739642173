/*
 * @Author: Chenck
 * @Date: 2022-03-14 15:34:56
 * @LastEditors: Chenck
 * @LastEditTime: 2022-07-30 16:26:56
 * @PageName: 页面名称
 */
var getters = {
  userName: function userName(state) {
    return state.app.userName;
  },
  language: function language(state) {
    return state.language;
  },
  historyKeywordList: function historyKeywordList(state) {
    return state.historyKeywordList;
  },
  homepageFixed: function homepageFixed(state) {
    return state.homepageFixed;
  },
  areaName: function areaName(state) {
    return state.areaName;
  },
  historyCity: function historyCity(state) {
    return state.historyCity;
  },
  areaId: function areaId(state) {
    return state.areaId;
  },
  countryId: function countryId(state) {
    return state.countryId;
  },
  cityType: function cityType(state) {
    return state.cityType;
  },
  historyCityLang: function historyCityLang(state) {
    return state.historyCityLang;
  },
  isAndroid: function isAndroid(state) {
    return state.isAndroid;
  },
  isIOSApp: function isIOSApp(state) {
    return state.isIOSApp;
  },
  isYtqApp: function isYtqApp(state) {
    return state.isYtqApp;
  },
  isMini: function isMini(state) {
    return state.isMini;
  },
  qrcode: function qrcode(state) {
    return state.qrcode;
  },
  isFirstEntry: function isFirstEntry(state) {
    return state.isFirstEntry;
  },
  nftList: function nftList(state) {
    return state.nftList;
  },
  otherPlatforms: function otherPlatforms(state) {
    return state.otherPlatforms;
  },
  selectGroupOwner: function selectGroupOwner(state) {
    return state.selectGroupOwner;
  }
};
export default getters;