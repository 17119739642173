import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/*
 * @Author: Chenck
 * @Date: 2022-03-14 15:34:56
 * @LastEditors: Chenck
 * @LastEditTime: 2023-03-03 14:23:09
 * @PageName: 页面名称
 */
import cifit from './model/cifit';
import cinde from './model/cinde';
import common from './model/common';
import yaokeyuyue from './model/yaokeyuyue';
import myPage from './model/myPage';
import appIndex from './model/appIndex';
import recommend from './model/recommend';
import home from './model/home';
import goldkey from './model/goldkey';
import exhibition from './model/exhibition';
import pcHotCity from './model/pcHotCity';
import publicHtml from './model/publicHtml';
import signup from './model/signup';
/**
 * 基础路由
 * @type { *[] }
 */

export var constantRouterMap = [{
  path: '/',
  component: function component() {
    return import('@/views/layouts/index');
  },
  redirect: '/home',
  meta: {
    title: '首页',
    keepAlive: false
  },
  children: [{
    path: '/home',
    name: 'Home',
    component: function component() {
      return import('@/views/home/index');
    },
    meta: {
      title: 'home',
      keepAlive: false
    }
  }, {
    path: '/about',
    name: 'About',
    component: function component() {
      return import('@/views/home/about');
    },
    meta: {
      title: 'about_me',
      keepAlive: false
    }
  }].concat(_toConsumableArray(cinde), _toConsumableArray(cifit), _toConsumableArray(common), _toConsumableArray(yaokeyuyue), _toConsumableArray(myPage), _toConsumableArray(appIndex), _toConsumableArray(recommend), _toConsumableArray(home), _toConsumableArray(goldkey), _toConsumableArray(exhibition))
}].concat(_toConsumableArray(signup), _toConsumableArray(pcHotCity), _toConsumableArray(publicHtml));