/*
 * @Descripttion: gm-h5
 * @version: 2.0
 * @Author: Chenck
 * @Date: 2022-03-18 14:57:43
 * @LastEditors: Chenck
 * @LastEditTime: 2023-03-06 23:19:05
 */
var mutations = {
  SET_HISTORY_KEWORD_LIST: function SET_HISTORY_KEWORD_LIST(state, historyKeywordList) {
    state.historyKeywordList = historyKeywordList;
  },
  SET_USER_NAME: function SET_USER_NAME(state, name) {
    state.userName = name;
  },
  // 修改 language
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    localStorage.setItem('language', language);
  },
  SET_HOME_PAGE_FIXED: function SET_HOME_PAGE_FIXED(state, query) {
    state.homepageFixed = query;
  },
  // 设置历史城市
  SET_HISTORY_CITY: function SET_HISTORY_CITY(state, historyCity) {
    state.historyCity = historyCity;
  },
  // 设置历史城市存储语言
  SET_HISTORY_CITY_LANG: function SET_HISTORY_CITY_LANG(state, historyCityLang) {
    state.historyCityLang = historyCityLang;
  },
  SET_CHANNEL_ID: function SET_CHANNEL_ID(state, channelId) {
    state.channelId = channelId;
  },
  SET_COUNTRY_ID: function SET_COUNTRY_ID(state, countryId) {
    state.countryId = countryId;
  },
  SET_DEFAULT_CITY: function SET_DEFAULT_CITY(state, areaName) {
    state.areaName = areaName;
  },
  SET_DEFAULT_CITY_ID: function SET_DEFAULT_CITY_ID(state, areaId) {
    state.areaId = areaId;
  },
  SET_DEFAULT_CITY_NAME: function SET_DEFAULT_CITY_NAME(state, defaultCityName) {
    state.defaultCityName = defaultCityName;
  },
  SET_CITY_TYPE: function SET_CITY_TYPE(state, cityType) {
    state.cityType = cityType;
  },
  SET_QRCODE: function SET_QRCODE(state, qrcode) {
    state.qrcode = qrcode;
  },
  IS_FIRST_ENTRY: function IS_FIRST_ENTRY(state, isFirstEntry) {
    state.isFirstEntry = isFirstEntry;
  },
  NFT_LIST: function NFT_LIST(state, isFirstEntry) {
    state.nftList = isFirstEntry;
  },
  OTHERPLATFORMS: function OTHERPLATFORMS(state, otherPlatforms) {
    state.otherPlatforms = otherPlatforms;
  },
  SELECT_GROUP_OWNER: function SELECT_GROUP_OWNER(state, selectGroupOwner) {
    state.selectGroupOwner = selectGroupOwner;
  }
};
export default mutations;