import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import Vue from 'vue';
import * as filter from './filter';
Object.keys(filter).forEach(function (k) {
  return Vue.filter(k, filter[k]);
});
Vue.prototype.$formatDate = Vue.filter('formatDate');
Vue.prototype.$hidePhone = Vue.filter('hidePhone');