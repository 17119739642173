import Cookies from 'js-cookie';
import storage from 'good-storage';
export var LANGUAGE = 'Language'; // 语言

export var X_TOKEN = 'token'; // 设置token

export var HISTORY_KEWORD_LIST = 'historyKeyWrodList'; // 历史搜索数据

export var HISTORY_CITY = 'History_City'; // 历史城市

export var HISTORY_CITY_LANG = 'History_City_Lang'; // 历史城市存储语言

export var CHANNEL_ID = 'Channel_Id'; // channelId

export var DEFAULT_CITY = 'Default_City'; // 默认城市

export var DEFAULT_CITY_ID = 'Default_City_Id'; // 默认城市ID

export var DEFAULT_CITY_NAME = 'Default_City_Name'; // 不带 省市区

export var CITY_TYPE = 'City_Type'; // 地区类型 1-境内 2-境外

export var COUNTRY_ID = 'countryId'; // 境外id

export var QRCODE = 'qrcode'; // 二维码

export var IS_FIRST_ENTRY = 'IS_FIRST_ENTRY'; // 是否是首次进入视频

export var NFT_LIST = 'NFT_LIST'; // 被动任务列表

export var OTHERPLATFORMS = 'otherPlatforms'; // 被动任务列表

/**
 * 设置cookie
 * @param key
 * @param value
 * @param options
 * @returns {*}
 */

export function saveCookie(key, value, options) {
  Cookies.set(key, value, options);
  return value;
}
/**
 * 获取cookie
 * @param key
 * @param defaultValue
 * @returns {*}
 */

export function loadCookie(key, defaultValue) {
  return Cookies.get(key) || defaultValue;
}
/**
 * 删除cookie
 * @param key
 * @returns {string}
 */

export function removeCookie(key) {
  Cookies.remove(key);
  return '';
}
/**
 * 设置本地存储
 * @param key
 * @param value
 * @returns {*}
 */

export function saveStorage(key, value) {
  storage.set(key, value);
  return value;
}
/**
 * 获取本地存储
 * @param key
 * @param defaultValue
 * @returns {*}
 */

export function loadStorage(key, defaultValue) {
  return storage.get(key, defaultValue);
}
/**
 * 删除本地存储
 * @param key
 * @returns {string}
 */

export function removeStorage(key) {
  storage.remove(key);
  return '';
}
/**
 * 保存会话存储
 * @param key
 * @param value
 * @returns {*}
 */

export function saveSessionStorage(key, value) {
  storage.session.set(key, value);
  return value;
}
/**
 * 获取会话存储
 * @param key
 * @param defaultValue
 * @returns {*}
 */

export function loadSessionStorage(key, defaultValue) {
  return storage.session.get(key, defaultValue);
}
/**
 * 删除会话存储
 * @param key
 * @returns {string}
 */

export function removeSessionStorage(key) {
  storage.session.remove(key);
  return '';
}