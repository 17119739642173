import "core-js/modules/es.object.to-string";

/*
 * @Author: Chenck
 * @Date: 2022-03-16 09:20:59
 * @LastEditors: Chenck
 * @LastEditTime: 2022-08-25 14:02:17
 * @PageName: 页面名称
 */
// 要客预约
export default [{
  path: '/vip-appointment',
  name: 'VipAppointment',
  component: function component() {
    return import('@/views/yaokeyuyue/VipAppointment');
  },
  meta: {
    title: 'mini_html_vip_appointment',
    keepAlive: true,
    appColor: 'EF2D2D',
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/vip-government',
  name: 'VipGovernment',
  component: function component() {
    return import('@/views/yaokeyuyue/model/Government');
  },
  meta: {
    title: 'government',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.content-item-wrap'
  }
}, {
  path: '/vip-capital',
  name: 'VipCapital',
  component: function component() {
    return import('@/views/yaokeyuyue/model/Capital');
  },
  meta: {
    title: 'capital',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.capital-content'
  }
}, {
  path: '/vip-exhibitors',
  name: 'VipExhibitors',
  component: function component() {
    return import('@/views/yaokeyuyue/model/Exhibitors');
  },
  meta: {
    title: 'exhibitors',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/vip-merchants',
  name: 'VipMerchants',
  component: function component() {
    return import('@/views/yaokeyuyue/model/Merchants');
  },
  meta: {
    title: 'merchant',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/vip-speakers',
  name: 'VipSpeakers',
  component: function component() {
    return import('@/views/yaokeyuyue/model/Speakers');
  },
  meta: {
    title: 'speaker',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/vip-becomeVip',
  name: 'VipBecomeVip',
  component: function component() {
    return import('@/views/yaokeyuyue/model/BecomeVip');
  },
  meta: {
    title: 'apply_becomes_a_crowd',
    keepAlive: false,
    appColor: 'FFFFFF'
  }
}, {
  path: '/vip-details',
  name: 'Vipdetails',
  component: function component() {
    return import('@/views/yaokeyuyue/model/Vipdetails');
  },
  meta: {
    title: 'sexual_details',
    keepAlive: false
  }
}];