require("core-js/modules/es.array.concat");

/*
 * @Author: Chenck
 * @Date: 2022-03-14 15:34:56
 * @LastEditors: Chenck
 * @LastEditTime: 2023-04-24 17:52:21
 * @PageName: 页面名称
 */
module.exports = {
  title: 'h5',
  baseUrl: 'https://h5.yunzhantest.top/',
  // 测试项目地址
  baseApi: 'https://zuul.yunzhantest.top',
  // 测试api请求地址
  nftBaseApi: 'https://cifitmeta.hundunqishi.com',
  nftbaseUrl: 'https://cifitmeta.hundunqishi.com',
  yuanyuzhouUrl: 'https://expo98.yunzhanyun.com',
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://imgs.solui.cn',
  ossBaseHost: 'b-dev.oss-accelerate.aliyuncs.com',
  ossProcessCJ: function ossProcessCJ(w, h) {
    // 图片处理，缩放裁剪
    return "x-oss-process=image/auto-orient,1/resize,m_fill,w_".concat(w, ",h_").concat(h, "/quality,q_100");
  },
  ossProcesswXh: function ossProcesswXh(w, h) {
    // 图片处理，缩放填充
    return "x-oss-process=image/auto-orient,1/resize,m_pad,w_".concat(w, ",h_").concat(h, "/quality,q_100");
  }
};