import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.search";
import "vant/es/locale/style";
import _Locale from "vant/es/locale";

/*
 * @Author: yyh 1637218164@qq.com
 * @Date: 2023-05-08 20:55:44
 * @LastEditors: yyh 1637218164@qq.com
 * @LastEditTime: 2023-05-25 10:24:34
 * @FilePath: \h5-ytz\src\assets\i18n\i18n.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh from './lang/zh';
import en from './lang/en';
import store from '@/store'; //  Locale 组件实现多语言支持，使用 Locale.use 方法可以切换当前使用的语言。

// 引入英文语言包
import enUS from 'vant/lib/locale/lang/en-US'; // 引入中文语言包

import zhCN from 'vant/lib/locale/lang/zh-CN';
import { queryURLParameter } from '@/utils/index';
Vue.use(VueI18n);
var search = window.location.search;
var params = queryURLParameter(search);

if (params['X-Access-Lang']) {
  if (store.getters.language !== params['X-Access-Lang']) {
    store.dispatch('setQrcode', '');
  }

  console.warn("params['X-Access-Lang']", params['X-Access-Lang']);
  store.dispatch('setLanguage', params['X-Access-Lang']);
  console.log(store.getters.language, 'store.getters.language');
}

var messages = {
  'en-us': en,
  'zh-cn': zh
};
/**
用于推送资讯的中英文语言切换
const routeLang = params['lang'] === 'en' ? 'en' : params['lang'] === 'zh' ? 'zh' : ''
locale: routeLang || store.getters.language, //语言标识
*/

var i18n = new VueI18n({
  locale: store.getters.language,
  //语言标识
  messages: messages,
  silentTranslationWarn: true
});

function locales(language) {
  if (language === 'zh-cn') {
    _Locale.use('zh-cn', zhCN); //切换成中文

  }

  if (language === 'en-us') {
    _Locale.use('en-us', enUS); //切换成英文

  }
}

Vue.prototype.$Local = locales; //把locales发法放入vue的原型对象中

locales(store.getters.language); //调用locales方法传入语言标识

export default i18n;