import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _objectSpread from "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
import NFTCollection from '@/components/NFTCollection';
import { mapGetters } from 'vuex';
import { getUserTaskInfo } from '@/api/nft';
import { loadCookie } from '@/utils/cache';
export default {
  name: 'App',
  components: {
    NFTCollection: NFTCollection
  },
  data: function data() {
    return {
      nftShow: false,
      token: loadCookie('token')
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['nftList'])), {}, {
    key: function key() {
      return this.$route.query.timer;
    }
  }),
  watch: {
    $route: {
      immediate: true,
      // 一旦监听到路由的变化立即执行
      handler: function handler(to, from) {
        var _this = this;

        this.nftShow = false; // nftType为1是主动型,2是被动型

        var nftType = to.query.nftType || 0;
        var list = this.nftList !== undefined && this.nftList.length > 0 ? JSON.parse(this.nftList) : [];

        if (parseInt(nftType) === 1 && to.query.taskId) {
          this.getNftTaskInfo(1, to.query.taskId);
        } else if (list.length > 0 && this.$store.getters.language !== 'en-us') {
          var nowDate = new Date();
          var nftObj = list.find(function (item) {
            var type = to.query.type || '';

            var url1 = _this.getUrlRelativePath(item.url);

            var url2 = _this.getUrlRelativePath2(item.url, to.path, type);

            var startTime = new Date(item.startTime);
            var endTime = new Date(item.endTime);
            return url1 === url2 && nowDate >= startTime && nowDate <= endTime;
          });

          if (nftObj !== undefined) {
            // 判断当前任务还剩多少次数
            this.getNftTaskInfo(2, nftObj.id);
          }
        }
      }
    }
  },
  methods: {
    // 获取用户nft任务信息
    getNftTaskInfo: function getNftTaskInfo(nftType, taskId) {
      var _this2 = this;

      var token = loadCookie('token') || '';

      if (!token) {
        return false;
      }

      getUserTaskInfo({
        taskId: taskId,
        token: token
      }).then(function (res) {
        if (parseInt(res.code) === 200) {
          if (res.data.remainingTimes > 0) {
            _this2.nftShow = true;

            _this2.$nextTick(function () {
              _this2.$refs.nftRef.init({
                nftType: nftType,
                taskId: taskId,
                downTime: res.data.taskDuration
              });
            });
          }
        }
      });
    },
    // 获取相对路径
    getUrlRelativePath: function getUrlRelativePath(url) {
      var _arrUrl$, _arrUrl$2;

      var arrUrl = url.split('//');
      var start = (_arrUrl$ = arrUrl[1]) === null || _arrUrl$ === void 0 ? void 0 : _arrUrl$.indexOf('/');
      var relUrl = (_arrUrl$2 = arrUrl[1]) === null || _arrUrl$2 === void 0 ? void 0 : _arrUrl$2.substring(start);
      return relUrl;
    },
    getUrlRelativePath2: function getUrlRelativePath2(url, indexUrl, type) {
      var _arrUrl$3, _arrUrl$4, _relUrl;

      var arrUrl = url.split('//');
      var start = (_arrUrl$3 = arrUrl[1]) === null || _arrUrl$3 === void 0 ? void 0 : _arrUrl$3.indexOf('/');
      var relUrl = (_arrUrl$4 = arrUrl[1]) === null || _arrUrl$4 === void 0 ? void 0 : _arrUrl$4.substring(start);

      if (((_relUrl = relUrl) === null || _relUrl === void 0 ? void 0 : _relUrl.indexOf('?')) != -1) {
        relUrl = "".concat(indexUrl, "?type=").concat(type);
      } else {
        relUrl = indexUrl;
      }

      return relUrl;
    }
  },
  filters: {
    getUrl: function getUrl() {}
  }
};