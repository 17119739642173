import { render, staticRenderFns } from "./NFTCollection.vue?vue&type=template&id=49f8ec7a&scoped=true&"
import script from "./NFTCollection.vue?vue&type=script&lang=js&"
export * from "./NFTCollection.vue?vue&type=script&lang=js&"
import style0 from "./NFTCollection.vue?vue&type=style&index=0&id=49f8ec7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f8ec7a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49f8ec7a')) {
      api.createRecord('49f8ec7a', component.options)
    } else {
      api.reload('49f8ec7a', component.options)
    }
    module.hot.accept("./NFTCollection.vue?vue&type=template&id=49f8ec7a&scoped=true&", function () {
      api.rerender('49f8ec7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/NFTCollection.vue"
export default component.exports