import "core-js/modules/es.object.to-string";

/*
 * @Author: Chenck
 * @Date: 2022-08-18 18:24:05
 * @LastEditors: Chenck
 * @LastEditTime: 2022-08-31 17:58:39
 * @PageName: 页面名称
 */
// 金钥匙
export default [{
  path: '/pchotcity',
  name: 'pcHotCity',
  component: function component() {
    return import('@/views/pcHotCity/Home');
  },
  meta: {
    title: '中国投资热点城市',
    keepAlive: false
  }
}, {
  path: '/pchotcity',
  component: function component() {
    return import('@/views/pcHotCity/layouts/index');
  },
  redirect: '/pchotcity',
  meta: {
    title: '中国投资热点城市',
    keepAlive: false
  },
  children: [{
    path: '/pcintroduction',
    name: 'pcIntroduction',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/Introduction');
    },
    meta: {
      title: '专区简介',
      keepAlive: false
    }
  }, {
    path: '/pcpark',
    name: 'pcPark',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/Park');
    },
    meta: {
      title: '平台载体',
      keepAlive: false
    }
  }, {
    path: '/pcparkdetail',
    name: 'pcParkDetail',
    component: function component() {
      return import('@/views/pcHotCity/modelDetail/ParkDetail');
    },
    meta: {
      title: '详情',
      keepAlive: false,
      parentPath: 'pcpark',
      parentName: '平台载体'
    }
  }, {
    path: '/pcproject',
    name: 'pcProject',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/Project');
    },
    meta: {
      title: '项目清单',
      keepAlive: false
    }
  }, {
    path: '/pcprojectdetail',
    name: 'pcProjectDetail',
    component: function component() {
      return import('@/views/pcHotCity/modelDetail/ProjectDetail');
    },
    meta: {
      title: '详情',
      keepAlive: false,
      parentPath: 'pcproject',
      parentName: '项目清单'
    }
  }, {
    path: '/pcpolicy',
    name: 'pcPolicy',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/Policy');
    },
    meta: {
      title: '政策法规',
      keepAlive: false
    }
  }, {
    path: '/pcpolicydetail',
    name: 'pcPolicyDetail',
    component: function component() {
      return import('@/views/pcHotCity/modelDetail/PolicyDetail');
    },
    meta: {
      title: '详情',
      keepAlive: false,
      parentPath: 'pcpolicy',
      parentName: '政策法规'
    }
  }, {
    path: '/pccluster',
    name: 'pcCluster',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/Cluster');
    },
    meta: {
      title: '产业集群',
      keepAlive: false
    }
  }, {
    path: '/pcclusterdetail',
    name: 'pcClusterDetail',
    component: function component() {
      return import('@/views/pcHotCity/modelDetail/ClusterDetail');
    },
    meta: {
      title: '详情',
      keepAlive: false,
      parentPath: 'pccluster',
      parentName: '产业集群'
    }
  }, {
    path: '/pcnews',
    name: 'pcNews',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/News');
    },
    meta: {
      title: '专区动态',
      keepAlive: false
    }
  }, {
    path: '/pcnewsdetail',
    name: 'pcNewsDetail',
    component: function component() {
      return import('@/views/pcHotCity/modelDetail/NewsDetail');
    },
    meta: {
      title: '详情',
      keepAlive: false,
      parentPath: 'pcnews',
      parentName: '专区动态'
    }
  }, {
    path: '/pcactivity',
    name: 'pcActivity',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/Activity');
    },
    meta: {
      title: '活动专区',
      keepAlive: false
    }
  }, {
    path: '/pcactivitydetail',
    name: 'pcActivityDetail',
    component: function component() {
      return import('@/views/pcHotCity/modelDetail/ActivityDetail');
    },
    meta: {
      title: '详情',
      keepAlive: false,
      parentPath: 'pcactivity',
      parentName: '活动专区'
    }
  }, {
    path: '/pcenterprise',
    name: 'pcEnterprise',
    component: function component() {
      return import('@/views/pcHotCity/modelLevel/Enterprise');
    },
    meta: {
      title: '名企窗口',
      keepAlive: false
    }
  }, {
    path: '/pcenterprisedetail',
    name: 'pcEnterpriseDetail',
    component: function component() {
      return import('@/views/pcHotCity/modelDetail/EnterpriseDetail');
    },
    meta: {
      title: '详情',
      keepAlive: false,
      parentPath: 'pcenterprise',
      parentName: '名企窗口'
    }
  }]
}, {
  path: '/pc-middlepage',
  name: 'PcMiddlePage',
  component: function component() {
    return import('@/views/pcHotCity/PcMiddlePage');
  },
  meta: {
    title: '投洽会元宇宙',
    keepAlive: false
  }
}];