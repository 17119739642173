var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nft" }, [
    _vm.downTime > 0 && _vm.count !== ""
      ? _c("div", { staticClass: "countdown" }, [
          _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.count))]),
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.$t("nft_collection.get_exhibits")))
          ])
        ])
      : _vm._e(),
    _vm.downTime === 0 ? _c("div", { staticClass: "mask" }) : _vm._e(),
    _vm.info.isWin
      ? _c("div", { staticClass: "exhibits" }, [
          _c(
            "div",
            { staticClass: "exhibits-box" },
            [
              _c("div", { staticClass: "name" }, [
                _vm._v("恭喜获得“" + _vm._s(_vm.info.nftName) + "”数字展品")
              ]),
              _c("div", { staticClass: "img-box" }, [
                _c("img", { attrs: { src: _vm.info.img, alt: "" } })
              ]),
              _c("div", { staticClass: "line" }),
              _c(
                "van-button",
                { staticClass: "btn", on: { click: _vm.goUrl } },
                [_vm._v(_vm._s(_vm.$t("nft_collection.go_exhibits")))]
              )
            ],
            1
          ),
          _c("img", {
            staticClass: "close",
            attrs: {
              src: require("../../src/assets/images/nft/icon-nft-close.png"),
              alt: ""
            },
            on: { click: _vm.closeBtn }
          })
        ])
      : _vm._e(),
    !_vm.info.isWin && _vm.bagOpen > 0
      ? _c(
          "div",
          {
            staticClass: "bag",
            class: { "bag-open": _vm.bagOpen === 2 || _vm.bagOpen === 3 }
          },
          [
            _c(
              "p",
              {
                staticClass: "name",
                class: { "box-enlarge": _vm.bagOpen === 2 }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.bagOpen === 1
                        ? "" + _vm.$t("nft_collection.get_bag_text")
                        : "" + _vm.$t("nft_collection.sorry_bag_text")
                    ) +
                    " "
                )
              ]
            ),
            _c("div", [
              _vm.bagOpen === 1
                ? _c("img", {
                    ref: "bagImgRef",
                    staticClass: "img",
                    class: { "img-tingle": _vm.tingleShow },
                    attrs: {
                      src: require("../../src/assets/images/nft/ico-box.png"),
                      alt: ""
                    }
                  })
                : _c("img", {
                    staticClass: "img-open",
                    class: { "box-enlarge": _vm.bagOpen === 2 },
                    attrs: {
                      src: require("../../src/assets/images/nft/icon-box-open.png"),
                      alt: ""
                    }
                  })
            ]),
            _c(
              "van-button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    _vm.bagOpen === 1 ? _vm.bagBtn() : _vm.closeBtn()
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.bagOpen === 1
                      ? "" + _vm.$t("nft_collection.open_bag_text")
                      : "" + _vm.$t("nft_collection.sorry_bg_confirm")
                  )
                )
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }