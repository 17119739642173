import "core-js/modules/es.object.to-string";

/*
 * @Author: Chenck
 * @Date: 2022-08-21 10:14:30
 * @LastEditors: Chenck
 * @LastEditTime: 2022-08-25 20:46:09
 * @PageName: 页面名称
 */
export default [{
  path: '/down-app',
  name: 'DownApp',
  component: function component() {
    return import('@/views/publicHtml/DownApp');
  },
  meta: {
    title: '云上投洽会',
    keepAlive: false
  }
}];