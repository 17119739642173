import "core-js/modules/es.object.to-string";
// 金钥匙
export default [{
  path: '/goldkey-policy',
  name: 'GoldkeyPolicy',
  component: function component() {
    return import('@/views/goldkey/Policy');
  },
  meta: {
    title: 'look_for_policies',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/goldkey-company',
  name: 'GoldkeyCompany',
  component: function component() {
    return import('@/views/goldkey/Company');
  },
  meta: {
    title: 'look_for_enterprises',
    keepAlive: true,
    scrollTop: 0,
    scrollClassName: '.app-page'
  }
}, {
  path: '/goldkey-circle',
  name: 'SocialCircle',
  component: function component() {
    return import('@/views/goldkey/SocialCircle');
  },
  meta: {
    title: 'industry_social_hub',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/goldkey-convention',
  name: 'InvestmentConvention',
  component: function component() {
    return import('@/views/goldkey/InvestmentConvention');
  },
  meta: {
    title: 'investment_circle_convention',
    keepAlive: false
  }
}, {
  path: '/goldkey-release',
  name: 'Release',
  component: function component() {
    return import('@/views/goldkey/Release');
  },
  meta: {
    title: 'dynamic_upload',
    keepAlive: false
  }
}, {
  path: '/goldkey-socialDetail',
  name: 'SocialDetail',
  component: function component() {
    return import('@/views/goldkey/SocialDetail');
  },
  meta: {
    title: 'mini_html_investment_circle',
    keepAlive: false
  }
}, {
  path: '/goldkey-like',
  name: 'LikeList',
  component: function component() {
    return import('@/views/goldkey/LikeList');
  },
  meta: {
    title: 'investor_praise',
    keepAlive: false
  }
}];