import "core-js/modules/es.object.to-string";

/*
 * @Author: YYH
 * @LastEditors: Chenck
 * @PageName: 报名
 */
export default [{
  path: '/neuemesse',
  name: 'NeueMesse',
  component: function component() {
    return import('@/views/signup/NeueMesse');
  },
  meta: {
    title: 'shareTitle',
    keepAlive: false
  }
}, {
  path: '/wuyiSummit',
  name: 'WuyiSummit',
  component: function component() {
    return import('@/views/signup/WuyiSummit');
  },
  meta: {
    title: 'shareTitle',
    keepAlive: false
  }
}, {
  path: '/documents-application',
  name: 'DocumentsApplication',
  component: function component() {
    return import('@/views/signup/DocumentsApplication');
  },
  meta: {
    title: 'certificate_application',
    keepAlive: false
  }
}, {
  path: '/resultPage',
  name: 'ResultPage',
  component: function component() {
    return import('@/views/signup/ResultPage');
  },
  meta: {
    title: 'result_page',
    keepAlive: false
  }
}, {
  path: '/interestedExhibitions',
  name: 'InterestedExhibitions',
  component: function component() {
    return import('@/views/signup/model/InterestedExhibitions');
  },
  meta: {
    title: 'interested_exhibition',
    keepAlive: false
  }
}, {
  path: '/reg-information',
  name: 'RegInformation',
  component: function component() {
    return import('@/views/signup/RegInformation');
  },
  meta: {
    title: 'register_information',
    keepAlive: false
  }
}, {
  path: '/visitorRegInfo',
  name: 'VisitorRegInfo',
  component: function component() {
    return import('@/views/signup/model/VisitorRegInfo');
  },
  meta: {
    title: 'register_information',
    keepAlive: false
  }
}, {
  path: '/exhibitionRegInfo',
  name: 'ExhibitionRegInfo',
  component: function component() {
    return import('@/views/signup/model/ExhibitionRegInfo');
  },
  meta: {
    title: 'register_information',
    keepAlive: false
  }
}, {
  path: '/receiveInfo',
  name: 'ReceiveInfo',
  component: function component() {
    return import('@/views/signup/ReceiveInfo');
  },
  meta: {
    title: 'collection_method',
    keepAlive: false
  }
}, {
  path: '/submittedSuccessfully',
  name: 'SubmittedSuccessfully',
  component: function component() {
    return import('@/views/signup/model/SubmittedSuccessfully');
  },
  meta: {
    title: 'submitted_successfully',
    keepAlive: false
  }
}, {
  path: '/registration-deadline',
  name: 'RegistrationDeadline',
  component: function component() {
    return import('@/views/signup/model/RegistrationDeadline');
  },
  meta: {
    title: 'registration_deadline',
    keepAlive: false
  }
}];