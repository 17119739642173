var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "keep-alive",
        [
          _vm.$route.meta.keepAlive
            ? _c("router-view", { key: _vm.key })
            : _vm._e()
        ],
        1
      ),
      !_vm.$route.meta.keepAlive
        ? _c("router-view", { key: _vm.key })
        : _vm._e(),
      _vm.nftShow
        ? _c("NFTCollection", {
            ref: "nftRef",
            on: {
              close: function($event) {
                _vm.nftShow = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }