//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AndroidAppBar',
  props: {
    type: {
      type: String,
      default: 'project'
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF'
    },
    color: {
      type: String,
      default: 'black'
    },
    whiteIcon: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      title: ''
    };
  },
  mounted: function mounted() {
    this.title = document.title;
    document.querySelector('body').setAttribute('style', "background-color:".concat(this.backgroundColor));
  },
  beforeCreate: function beforeCreate() {},
  beforeDestroy: function beforeDestroy() {
    document.querySelector('body').removeAttribute('style');
  },
  methods: {
    closeHandle: function closeHandle() {
      this.$ytqAndroidApp.goBack();
    },
    shareHandle: function shareHandle() {
      var that = this;
      this.$ytqAndroidApp.appBarMoreBtn({
        titleShare: '2133213',
        type: that.type,
        id: '123'
      });
    }
  }
};