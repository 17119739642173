import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/button/style";
import _Button from "vant/es/button";
// 按需全局引入 vant组件
import Vue from 'vue';
Vue.use(_Button);
Vue.use(_Cell);
Vue.use(_List);
Vue.use(_Tabbar).use(_TabbarItem);