import "core-js/modules/es.object.to-string";
// 商务部投促局
export default [{
  path: '/commerceIndex',
  // 商务局
  name: 'commerceIndex',
  component: function component() {
    return import('@/views/cinde/commerceIndex');
  },
  meta: {
    title: 'router_commerce',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/localCase',
  // 工作动态/通知公告
  name: 'LocalCase',
  component: function component() {
    return import('@/views/cinde/LocalCase');
  },
  meta: {
    title: 'cinde_localCase',
    keepAlive: true,
    scrollTop: 0
  },
  beforeEnter: function beforeEnter(to, from, next) {
    if (to.query.type == 'commerce-worktrends') {
      to.meta.title = 'cinde_localCase';
    } else {
      to.meta.title = 'commerce_indexNew_noticePublic';
    }

    next();
  }
}, {
  path: '/statistics',
  // 统计数据
  name: 'Statistics',
  component: function component() {
    return import('@/views/cinde/Statistics');
  },
  meta: {
    title: 'cinde_statistics',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/statisticsList',
  // 统计数据-更多列表
  name: 'StatisticsList',
  component: function component() {
    return import('@/views/cinde/StatisticsList');
  },
  meta: {
    title: 'cinde_statisticsList',
    keepAlive: true,
    scrollTop: 0
  }
}, {
  path: '/commerceChange',
  name: 'commerceChange',
  component: function component() {
    return import('@/views/cinde/commerceChange');
  },
  meta: {
    title: 'cooperate',
    keepAlive: true,
    scrollTop: 0
  }
}];