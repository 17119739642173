export default {
  namespaced: true,
  state: {
    userName: '',
    language: 'zh-cn'
  },
  mutations: {
    SET_USER_NAME: function SET_USER_NAME(state, name) {
      state.userName = name;
    }
  },
  actions: {
    // 设置name
    setUserName: function setUserName(_ref, name) {
      var commit = _ref.commit;
      commit('SET_USER_NAME', name);
    }
  }
};