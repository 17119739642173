import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import _objectSpread from "/u01/app/jenkins/workspace/merchants-platform-h5/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";

/*
 * @Author: Chenck
 * @Date: 2022-04-28 13:59:23
 * @LastEditors: Chenck
 * @LastEditTime: 2023-08-03 14:14:56
 * @PageName: 页面名称
 */
import { mapGetters } from 'vuex';
import { baseUrl as _baseUrl } from '@/config';
import { eventPoint } from '@/api/buryingPoint';
var publicMixin = {
  data: function data() {
    return {
      transferShowShareBtn: false,
      buryingPointParams: {
        // 埋点参数
        contentId: '',
        paramId: '',
        paramName: 1,
        eventName: ''
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['isAndroid', 'isIOSApp', 'isYtqApp', 'isMini', 'otherPlatforms'])), {}, {
    isAppOrMini: function isAppOrMini() {
      return this.isIOSApp || this.isMini || this.isAndroid;
    },
    baseUrl: function baseUrl() {
      return _baseUrl;
    },
    pcSpanBanner: function pcSpanBanner() {
      return 19;
    },
    pcSpan: function pcSpan() {
      return 18;
    }
  }),
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 数据埋点
    buryingPointHandle: function buryingPointHandle() {
      eventPoint(this.buryingPointParams).then(function (res) {});
    },

    /** 隐藏分享按钮 **/
    hiddenShareBtn: function hiddenShareBtn() {
      this.$ytqAndroidApp.appBarMoreBtn({
        showMore: false
      });
      this.$ytqIOSApp.shareBtnAction('2');
    },

    /** 显示分享按钮 **/
    showShareBtn: function showShareBtn() {
      this.transferShowShareBtn = true;
      this.$ytqAndroidApp.appBarMoreBtn({
        showMore: true
      });
      this.$ytqIOSApp.shareBtnAction('1');
    },
    getNum: function getNum(val, type) {
      var num1 = Math.floor(val).toString(); // 如果num1长度大于4(num1是万级别的)

      if (num1.length > 4) {
        // 如果num1长度大于8(num1是亿级别的)
        if (num1.length > 8) {
          // num1除以1亿再取整得到的数字再加'亿'
          var _num = (Math.floor(num1 / 100000000 * 100) / 100).toString();

          return !type ? this.handledecimal(_num) : this.$t('a_hundred_million');
        } else {
          // num1除以1亿再取整得到的数字再加'万'
          var _num2 = (Math.floor(num1 / 10000 * 100) / 100).toString();

          return !type ? this.handledecimal(_num2) : this.$t('ten_thousand');
        }
      }

      var num2 = (Math.floor(val * 100) / 100).toString();
      return !type ? num2 : '';
    },
    handledecimal: function handledecimal(e) {
      var rs = e.indexOf('.');

      if (rs < 0) {
        rs = e.length;
        e += '.';
      }

      var arr = e.split('.');
      var key = arr[1].length > 1 ? 0 : arr[1].length === 1 ? 1 : 2;
      if (!key) return e;

      while (e.length <= rs + key) {
        e += '0';
      }

      return e;
    }
  },
  destroyed: function destroyed() {
    if (this.transferShowShareBtn) {
      console.log('beforeDestroy+++++++++++++');
      this.hiddenShareBtn();
    }
  }
};
export { publicMixin };