/*
 * @Author: Chenck
 * @Date: 2022-08-18 20:50:47
 * @LastEditors: Chenck
 * @LastEditTime: 2022-08-31 16:27:50
 * @PageName: 页面名称
 */
import request from '@/utils/request';
import { nftBaseApi } from '@/config'; // 领取主动任务奖励藏品

export function drawInitiativeTaskNft(data) {
  return request({
    url: nftBaseApi + '/api/ext/drawInitiativeTaskNft',
    method: 'post',
    data: data
  });
} // 领取被动任务奖励藏品

export function drawPassiveTaskNft(data) {
  return request({
    url: nftBaseApi + '/api/ext/drawPassiveTaskNft',
    method: 'post',
    data: data
  });
} // 获取可用被动任务列表

export function getListPassiveTask(params) {
  return request({
    url: nftBaseApi + '/api/ext/listPassiveTask',
    method: 'get',
    hideloading: true,
    params: params
  });
} // 获取用户任务信息

export function getUserTaskInfo(data) {
  return request({
    url: nftBaseApi + '/api/ext/getUserTaskInfo',
    method: 'post',
    data: data
  });
}